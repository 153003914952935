import styled, { css } from 'styled-components';
import { Table } from 'antd';

export const HeaderTitle = styled.div`
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  color: rgba(41, 41, 43, 1) !important;
  margin: 0 !important;
`;

export const PaginationWrapper = styled.div`
  margin-top: 20px;

  .ant-pagination {
    display: flex;
    justify-content: flex-end;
  }
`;

export const PopoverItem = styled.div`
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

export const TableStyled = styled(Table)<{
  $isScreen350?: boolean;
  $isScreen1024?: boolean;
  $isScreen1099?: boolean;
  onRow?: any;
  $stickyHeader?: boolean;
}>`
  table {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    tbody tr:last-child {
      td:first-child {
        border-bottom-left-radius: 23px;
      }

      td:last-child {
        border-bottom-right-radius: 23px;
      }
    }
  }

  .areaButton {
    min-height: 40px;
    min-width: 40px;
  }

  .lineClamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.9;
  }

  .ant-table-thead {
    tr {
      th:nth-child(2) {
        min-width: 100px;
      }
    }
  }

  ${({ $stickyHeader }) =>
    $stickyHeader &&
    css`
      .ant-table-thead {
        position: sticky;
        top: 80px;
        z-index: 1000;
        outline: 1px solid #e8e8e9;
        overflow: hidden;
        border-start-start-radius: 24px !important;
        border-start-end-radius: 24px !important;
      }
    `}

  ${({ $isScreen350 }) =>
    $isScreen350 &&
    css`
      .ant-table-cell,
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table-wrapper tfoot > tr > th,
      .ant-table-wrapper tfoot > tr > td {
        padding: 8px 8px;
      }
    `}
  ${({ $isScreen1099 }) =>
    $isScreen1099 &&
    css`
      .ant-table-cell,
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table-wrapper tfoot > tr > th,
      .ant-table-wrapper tfoot > tr > td {
        padding: 16px 16px;
      }
    `}
  ${({ $isScreen1024 }) =>
    $isScreen1024 &&
    css`
      .ant-table-cell,
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table-wrapper tfoot > tr > th,
      .ant-table-wrapper tfoot > tr > td {
        padding: 16px 16px;
      }
    `}
  & .ant-table-expanded-row-level-1 .ant-table-cell {
    &:nth-child(1) {
      padding: 0;
    }
  }

  &
    .ant-table-expanded-row
    .ant-table-cell
    .ant-table-wrapper
    .ant-spin-nested-loading
    .ant-spin-container
    > .ant-table {
    margin-block: 0;
    margin-inline: 0;
  }

  & table table {
    border-radius: 0;
    border: none;
    box-shadow: 1px 0px 19px -7px #a7a5a5 inset;

    //&. .ant-table-tbody > tr > .ant-table-cell {
    //  background: #c7c7c7 !important;
    //}
    //
    & thead > tr:first-child > *:first-child {
      border-start-start-radius: 0 !important;
    }

    & thead > tr:first-child > *:last-child {
      border-start-end-radius: 0 !important;
    }

    & .ant-table-thead > tr > td {
      //background: none;
      //background: #fafafa;
      //background: #dcdcdc;
      border-radius: 0;
      //border-start-start-radius: 0;
      //border-start-end-radius: 0;
    }
  }
`;
