import React from 'react';
import { Flex } from '../../../../shared/components/styled';
import { useTranslation } from 'react-i18next';
import { useRequestConfirmState } from '../../model/useRequestConfirmState';
import dayjs from 'dayjs';
import countryList from 'react-select-country-list';
import { Popover, Typography } from '@pankod/refine-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DateStyled } from '../OfferRequestConfirm.styles';
import { StyledText } from '../OfferRequestConfirm.styles';

const DatePlaceBlock = () => {
  const { t } = useTranslation(['shared']);

  const [userDate, setUserDate] = React.useState(new Date().toString());

  const requestData = useRequestConfirmState((state) => state.requestData);

  setInterval(() => {
    setUserDate(new Date().toString());
  }, 60000);

  return (
    <Flex gap={16}>
      <Flex align={'flex-start'} gap={16} vertical>
        <div>
          <StyledText>{t('shared:тексты.Дата обмена двоеточие')}</StyledText>{' '}
          <span>
            {dayjs(userDate)
              .add(1, 'hour')
              .format(`DD.MM.YYYY [${t('shared:тексты.в')}] HH:mm`)}
          </span>
        </div>
        <div>
          <StyledText>{t('shared:тексты.Место обмена двоеточие')}</StyledText>{' '}
          <span>{countryList().getLabel(requestData?.country ?? '')}</span>
        </div>
        {requestData?.offer?.isGuarantee && (
          <div>
            <DateStyled>
              <StyledText>
                {t('shared:тексты.Заморозка гаранта двоеточие')}
              </StyledText>{' '}
              {dayjs(userDate).format(
                `DD.MM.YYYY [${t('shared:тексты.в')}] HH:mm`
              )}
            </DateStyled>

            <Popover
              overlayInnerStyle={{ width: '265px' }}
              placement={'top'}
              color="#E5F9FF"
              content={
                <Flex align={'flex-start'} vertical>
                  <Typography.Text type={'secondary'}>
                    {requestData?.offer?.is_buying_crypto
                      ? t(
                          'shared:тексты.Клиент сможет отметить заявку как просроченную и разморозить гарант'
                        )
                      : t(
                          'shared:тексты.Вы сможете отметить заявку как просроченную и разморозить гарант'
                        )}
                  </Typography.Text>
                  <Typography.Text>
                    {dayjs(userDate)
                      .add(1.5, 'hour')
                      .format(`DD.MM.YYYY [${t('shared:тексты.в')}] HH:mm`)}
                  </Typography.Text>
                </Flex>
              }
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        )}
        <div>
          <StyledText>
            {t('shared:тексты.Заморозка курса двоеточие')}{' '}
          </StyledText>{' '}
          <span>
            {dayjs(userDate).format(
              `DD.MM.YYYY [${t('shared:тексты.в')}] HH:mm`
            )}
          </span>
        </div>
      </Flex>
    </Flex>
  );
};

export default DatePlaceBlock;
