import CreateOfferFooter from './components/CreateOfferFooter';
import { CreateOfferPageStyled } from './CreateOffer.styles';
import CreateOfferForm from '../../widgets/CreateOfferForm/ui/CreateOfferForm';
import { Form } from '@pankod/refine-antd';
import { useCreateOfferState } from '../../widgets/CreateOfferForm';
import { useEffect } from 'react';

type CreateOfferPropsType = {
  isGuarantee?: boolean;
};

const CreateOffer = ({ isGuarantee = true }: CreateOfferPropsType) => {
  const [form] = Form.useForm();

  const resetOfferForm = useCreateOfferState((state) => state.resetState);

  useEffect(() => {
    return () => resetOfferForm();
  }, []);

  return (
    <CreateOfferPageStyled>
      <CreateOfferForm form={form} isGuarantee={isGuarantee} />
      <CreateOfferFooter form={form} isGuarantee={isGuarantee} />
    </CreateOfferPageStyled>
  );
};

export default CreateOffer;
