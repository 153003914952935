import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Button, ButtonProps, Tooltip } from '@pankod/refine-antd';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { Flex } from 'shared/components/styled';
import { DEAL_STATUS } from 'shared/constants';
import { TDeals } from 'shared/types';
import {
  DEAL_ACTION,
  useDealActions,
} from 'features/DealsTable/ui/useDealActions';

const idActionButtons = 'deals-cancel-tooltip';

const actionTypeToIcon = (actionType: DEAL_ACTION): ButtonProps['icon'] => {
  switch (actionType) {
    case DEAL_ACTION.TOP_UP: {
      return <WalletOutlined style={{ color: '#00000073' }} />;
    }
    case DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED:
    case DEAL_ACTION.CONFIRM_PAYMENT_SENT: {
      return <CheckOutlined style={{ color: '#52c41a' }} />;
    }
    case DEAL_ACTION.PAY: {
      return <WalletOutlined style={{ color: '#00000073' }} />;
    }
    case DEAL_ACTION.OPEN_DISPUTE: {
      return <ExclamationCircleOutlined style={{ color: '#faad14' }} />;
    }
    case DEAL_ACTION.CANCEL_DEAL: {
      return <CloseOutlined style={{ color: '#ff4d4f' }} />;
    }
    case DEAL_ACTION.COMPLAINT: {
      return <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />;
    }
    case DEAL_ACTION.ACCEPT_DEAL: {
      return <CheckOutlined style={{ color: '#52c41a' }} />;
    }
    default: {
      return undefined;
    }
  }
};

const actionsToButtons = (
  deal: TDeals,
  actions: ReturnType<typeof useDealActions>,
  t: TFunction<['offers', 'shared']>,
  callback: (actionType: DEAL_ACTION) => void
): React.ReactNode[] => {
  return actions.map(({ type, disabled }) => {
    if (type === DEAL_ACTION.CANCEL_DEAL && disabled) {
      return (
        <Tooltip
          placement="topRight"
          title={t('Вы сможете отменить сделку через 30 минут')}
          getPopupContainer={() =>
            document.querySelector(`#${idActionButtons}`)!
          }
        >
          <ButtonDisabledStyled
            icon={<CloseOutlined style={{ color: '#00000040' }} />}
            type={'default'}
            className={'custom-button-grey-disable'}
          >
            {t('shared:кнопки.Отменить')}
          </ButtonDisabledStyled>
        </Tooltip>
      );
    }

    let label: string | null = null;

    if (type === DEAL_ACTION.TOP_UP) {
      label = t('shared:кнопки.Пополнить баланс');
    } else if (type === DEAL_ACTION.ACCEPT_DEAL) {
      label = t('shared:кнопки.Принять');
    } else if (type === DEAL_ACTION.CONFIRM_PAYMENT_SENT) {
      label = t('shared:кнопки.Отправил средства');
    } else if (type === DEAL_ACTION.PAY) {
      label = t('shared:кнопки.Оплатить');
    } else if (type === DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED) {
      label = t('Получил оплату');
    } else if (type === DEAL_ACTION.OPEN_DISPUTE) {
      label = t('Открыть спор');
    } else if (type === DEAL_ACTION.COMPLAINT) {
      label = t('Пожаловаться');
    } else if (type === DEAL_ACTION.CANCEL_DEAL && !disabled) {
      label =
        deal.status === DEAL_STATUS.pending
          ? t('Отклонить')
          : t('shared:кнопки.Отменить');
    }

    if (label) {
      return (
        <RegularButton
          key={type}
          actionType={type}
          label={label}
          callback={callback}
        />
      );
    }

    return null;
  });
};

const RegularButton: FC<{
  actionType: DEAL_ACTION;
  label: string;
  callback: (actionType: DEAL_ACTION) => void;
}> = ({ actionType, label, callback }) => {
  return (
    <Button
      onClick={() => callback(actionType)}
      icon={actionTypeToIcon(actionType)}
      type={'default'}
    >
      {label}
    </Button>
  );
};

interface IProps {
  deal: TDeals;
  actionHandler: (actionType: DEAL_ACTION) => void;
}

const ActionsBtnBlock: FC<IProps> = ({ deal, actionHandler }) => {
  const { t } = useTranslation(['offers', 'shared']);
  const actions = useDealActions(deal as TDeals);
  const buttons = actionsToButtons(deal, actions, t, actionHandler);

  return (
    <Flex gap={16} id={idActionButtons}>
      {buttons}
    </Flex>
  );
};

export default ActionsBtnBlock;

const ButtonDisabledStyled = styled(Button)`
  &&&&.custom-button-grey-disable {
    background: #0000000a;
    color: #00000040;
    border: 1px solid #00000026;

    &:hover {
      background: #0000000a;
      cursor: not-allowed;
    }
  }
`;
