import { axios } from 'shared/exios';
import { API_URL } from 'packages/keycloak-client/constants';

import { IMeta } from 'interfaces';
import { IResponseShell } from 'shared/interfaces';
import { TDeals } from 'shared/types';
import { TActiveFilter } from 'pages/offers/model/filters.types';
import { GuaranteeType } from 'interfaces';

export const getDeals = async (
  filters?: TActiveFilter[],
  page?: number,
  dealsType?: GuaranteeType | null
): Promise<{ data: TDeals[]; meta: IMeta }> => {
  try {
    const scopes = [];
    if (dealsType) {
      scopes.push({ name: dealsType });
      scopes.push({
        name:
          dealsType === 'withGuarantee'
            ? 'priorityStatuses'
            : 'priorityStatusesNonGuarantee',
      });
    }

    const { data } = await axios.post<IResponseShell<TDeals[]>>(
      `${API_URL}/exchange-point-offer-requests/search`,
      {
        filters,
        limit: 10,
        page: page ?? 1,
        includes: [
          { relation: 'offer' },
          { relation: 'offer.exchange_point.company' },
          { relation: 'requisite.currency' },
        ],
        sort: [{ field: 'created_at', direction: 'desc' }],
        scopes,
      }
    );
    return { data: data?.data, meta: data.meta };
  } catch {
    return {
      data: [],
      meta: {} as IMeta,
    };
  }
};
