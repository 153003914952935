import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { getExternalAccount } from '../../pages/offer-request-confirm/api/getExternalAccount';

type TSidebarState = {
  externalAccountBalance: number | string | null;
  externalAccountId: number | string | null;
  setExternalAccount: (companyID: number) => void;
};

export const useExternalAccountState = create<TSidebarState>()(
  devtools(
    (set) => ({
      externalAccountBalance: null,
      externalAccountId: null,
      setExternalAccount: async (companyID: number) => {
        const externalAccount = await getExternalAccount(companyID);
        if (externalAccount) {
          set(
            produce((draft) => {
              draft.externalAccountBalance = externalAccount.balance;
              draft.externalAccountId = externalAccount.id;
            })
          );
        }
      },
    }),
    {
      anonymousActionType: 'useExternalAccountState action',
      name: 'userState',
    }
  )
);
