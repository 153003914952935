import React, { useEffect, useMemo, useState } from 'react';
import './styles.css';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useGetSideBarElements } from './useGetSideBarElements';
import { SideBarCollapsedIcon, SideBarLogo } from '../../icons';
import Sider from 'antd/es/layout/Sider';
import { NavLink, useLocation } from '@pankod/refine-react-router-v6';
import { Col, ConfigProvider } from '@pankod/refine-antd';
import {
  SidebarWrapper,
  StyledLogoRow,
  StyledMenu,
  StyledButton,
  MarkStyled,
  SideBarWithMarkOrBadgeStyled,
  BadgeStyled,
  CollapsedMarkStyled,
} from './_styles';
import { useResize } from '../../../hooks/useResize';
import fb from '../../../../packages/fb/fb';
import { useSidebarState } from '../../../state/useSidebarState';

const Sidebar = (): JSX.Element => {
  let loc = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>();
  const { isScreen1300 } = useResize();

  const setIsCollapsedSidebar = useSidebarState(
    (state) => state.setIsCollapsedSidebar
  );

  const { guaranteePendingDealsCount, nonGuaranteePendingDealsCount } =
    useSidebarState((state) => state);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const activeKeys = useMemo(() => {
    const index = loc.pathname.indexOf('/', 1);

    const result: string[] = [];
    if (loc.pathname.includes('/transactions/request/')) {
      // страница сделки с гарантом
      result.push('/transactions');
    }
    if (loc.pathname.includes('/no-guarantee-transactions/request/')) {
      // страница сделки без гаранта
      result.push('/no-guarantee-transactions');
    }
    if (
      (loc.pathname === '/offers' || loc.pathname.includes('/offers/')) &&
      !loc.pathname.includes('/offers/request')
    ) {
      // список офферов с гарантом
      result.push('/offers');
    }
    if (
      (loc.pathname === '/no-guarantee-offers' ||
        loc.pathname.includes('/no-guarantee-offers/')) &&
      !loc.pathname.includes('/no-guarantee-offers/request')
    ) {
      // список офферов без гаранта
      result.push('/no-guarantee-offers');
    } else {
      const result =
        index !== -1 ? loc.pathname.substring(0, index) : loc.pathname;
      return [result];
    }
    return result;
  }, [loc]);

  const {
    DrawSideBarMenuElement,
    BOTTOM_SIDEBAR_ELEMENTS,
    SIDEBAR_CATEGORIES,
  } = useGetSideBarElements();
  const sidebarItems = useMemo(() => {
    const items: any[] = SIDEBAR_CATEGORIES[0].elements.map((sideItem) => {
      const item: Record<string, any> = {
        label: <NavLink to={sideItem.pathKey}>{sideItem.title}</NavLink>,
        key: sideItem.pathKey,
        icon: (
          <div>
            {sideItem.iconComponent()}
            {sideItem.pathKey === 'transactions' &&
              collapsed &&
              (!!guaranteePendingDealsCount ||
                !!nonGuaranteePendingDealsCount) && <CollapsedMarkStyled />}
          </div>
        ),
        onClick: sideItem.onClick,
        disabled: sideItem.disabled,
      };
      if (sideItem.children) {
        item.children = sideItem.children.map((childItem) => ({
          icon: childItem.iconComponent(),
          label: (
            <NavLink to={childItem.pathKey}>
              {childItem.withBadge ? (
                <SideBarWithMarkOrBadgeStyled
                  $isCollapsed={collapsed}
                  $isActive={childItem.pathKey === activeKeys[0]}
                >
                  <span>{childItem.title}</span>
                  {childItem.pathKey === '/transactions' && (
                    <BadgeStyled
                      showZero={false}
                      count={guaranteePendingDealsCount}
                      $isActive={childItem.pathKey === activeKeys[0]}
                    />
                  )}
                  {childItem.pathKey === '/no-guarantee-transactions' && (
                    <BadgeStyled
                      showZero={false}
                      count={nonGuaranteePendingDealsCount}
                      $isActive={childItem.pathKey === activeKeys[0]}
                    />
                  )}
                </SideBarWithMarkOrBadgeStyled>
              ) : (
                childItem.title
              )}
            </NavLink>
          ),
          key: childItem.pathKey,
        }));
        item.label = sideItem.withMark ? (
          <SideBarWithMarkOrBadgeStyled
            $isCollapsed={collapsed}
            $isActive={sideItem.pathKey === activeKeys[0]}
          >
            <span>{sideItem.title}</span>
            {(!!guaranteePendingDealsCount ||
              !!nonGuaranteePendingDealsCount) && <MarkStyled />}
          </SideBarWithMarkOrBadgeStyled>
        ) : (
          sideItem.title
        );
      }
      return item;
    });

    return items;
  }, [
    SIDEBAR_CATEGORIES,
    guaranteePendingDealsCount,
    nonGuaranteePendingDealsCount,
    collapsed,
  ]);

  const bottomSidebarItems = useMemo((): any[] => {
    return BOTTOM_SIDEBAR_ELEMENTS.map((sideItem) => {
      const item: Record<string, any> = {
        label: sideItem.title,
        key: sideItem.pathKey,
        icon: sideItem.iconComponent(),
        disabled: sideItem.disabled,
        onClick: sideItem.onClick,
      };
      return item;
    });
  }, [BOTTOM_SIDEBAR_ELEMENTS]);

  useEffect(() => {
    const key: string[] | undefined = openKeys;
    sidebarItems.forEach((item) => {
      if (
        item.children &&
        item.children.find((child: any) => child.key === activeKeys[0])
      ) {
        key?.push(item.key);
      }
    });
    setOpenKeys(key);
  }, [sidebarItems, activeKeys]);

  useEffect(() => {
    setCollapsed(!isScreen1300);
  }, [isScreen1300]);

  useEffect(() => {
    const clickLayont = fb.addListener('closeSideBar', (value: boolean) => {
      if (!collapsed && value) {
        setCollapsed(true);
      }
    });
    return () => {
      clickLayont.remove();
    };
  });

  useEffect(() => {
    setIsCollapsedSidebar(collapsed);
  }, [collapsed]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: '#ffffff',
            fontSize: 14,
          },
          Menu: {
            colorItemText: '#ffffff',
            radiusItem: 8,
            colorSubItemBg: '#1b1b25',
          },
        },
      }}
    >
      <Sider
        style={
          isScreen1300
            ? {}
            : { position: 'fixed', zIndex: '999', background: '#001529' }
        }
        width={264}
        collapsed={collapsed}
      >
        <SidebarWrapper>
          <StyledLogoRow justify="space-between" $isCollapsed={collapsed}>
            <Col>
              <NavLink to="/accounts">
                <Icon
                  component={collapsed ? SideBarCollapsedIcon : SideBarLogo}
                />
              </NavLink>
            </Col>
            <Col>
              <StyledButton
                type="link"
                onClick={toggleCollapsed}
                style={{
                  color: 'white',
                }}
                size="small"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              />
            </Col>
          </StyledLogoRow>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <StyledMenu
              mode="inline"
              rootClassName="sidebar-menu"
              selectedKeys={activeKeys}
              style={{ borderInlineEnd: 'unset' }}
              items={sidebarItems}
              openKeys={openKeys}
              onOpenChange={setOpenKeys}
              $isCollapsed={collapsed}
            />
            {/* <StyledBottomMenu
              items={bottomSidebarItems}
              mode="inline"
              style={{ borderInlineEnd: 'unset' }}
              selectedKeys={[]}
            /> */}
          </div>
        </SidebarWrapper>
      </Sider>
    </ConfigProvider>
  );
};

export { Sidebar };
