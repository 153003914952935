import { useCallback } from 'react';
import {
  BankOutlined,
  CheckCircleFilled,
  WalletOutlined,
} from '@ant-design/icons';

import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { axios } from 'shared/exios';
import { API_URL } from 'packages/keycloak-client/constants';
import {
  ButtonAsLinkStyled,
  CurrencyIconStyled,
  ModalStyled,
  SectionInfo,
  SectionLabel,
  SectionStyled,
} from './ModalConfirmPaymentSent.styles';
import { TDeals } from 'shared/types';

const requestConfirmPaymentUrl = (id: string | number) =>
  `${API_URL}/exchange-point-offer-requests/${id}/confirm-payment`;

const requestDeliverFiatUrl = (id: string | number) =>
  `${API_URL}/exchange-point-offer-requests/${id}/deliver-fiat`;

export const ModalConfirmPaymentSent = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalConfirmPaymentSent)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmPaymentSent).options
  );

  const handleChangeOfferRequestStatus = async () => {
    try {
      const isGuaranete = modalOptions.isGuarantee;

      await axios.put(
        isGuaranete
          ? requestConfirmPaymentUrl(modalOptions.id)
          : requestDeliverFiatUrl(modalOptions.id)
      );

      modalOptions?.refetchData();
      notification.success({
        message: t('shared:тексты.Подтверждение отправлено'),
        description: (
          <>
            {t(`shared:тексты.Подтверждение перевода средств по сделке`)}{' '}
            <ButtonAsLinkStyled>{modalOptions.name}</ButtonAsLinkStyled>
            {t(`shared:тексты.на покупку USDT успешно отправлено точка`)}
          </>
        ),
        icon: <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />,
        style: {
          borderLeft: '6px solid #13c2c2',
          width: '400px',
        },
      });
    } catch {
      notification.error({
        duration: 5,
        message: t('shared:тексты.Ошибка запроса'),
        description: t(
          'shared:ошибки.Произошла ошибка при выполнении запроса точка Пожалуйста запятая попробуйте повторить запрос позже точка'
        ),
        style: { borderLeft: '6px solid #FF4D4F' },
      });
      console.error('An error occurred while updating the transaction status');
    } finally {
      closeModal(ModalKeys.ModalConfirmPaymentSent);
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(ModalKeys.ModalConfirmPaymentSent);
  }, [closeModal]);

  const deal = modalOptions.deal as TDeals;
  const requisite = deal?.requisite ?? {};
  const currency = requisite.currency ?? {};
  const data = {
    code: currency.code,
    fullName: deal.full_name ?? '',
    paymentName: currency.name,
    requisite: deal?.requisites,
    type: currency.type,
  };

  const isEmoney = data.type === 'emoney';

  const DefaultIcon = isEmoney ? WalletOutlined : BankOutlined;

  return (
    <ModalStyled
      open={isModalOpen}
      onOk={handleChangeOfferRequestStatus}
      onCancel={handleCloseModal}
      title={t('shared:тексты.Подтверждение отправки средств')}
      footer={[
        <Button onClick={handleCloseModal}>
          {t('shared:кнопки.Отменить')}
        </Button>,
        <Button onClick={handleChangeOfferRequestStatus} type="primary">
          {t('shared:кнопки.Подтвердить')}
        </Button>,
      ]}
      width={636}
      closable={true}
      centered
    >
      <p>
        {t(
          'shared:тексты.Подтвердите перевод средств на выбранный клиентом способ оплаты'
        )}
      </p>
      <SectionStyled>
        <div>
          <SectionLabel>
            {t('shared:тексты.Способ оплаты двоеточие')}
          </SectionLabel>
          <SectionInfo>
            <CurrencyIconStyled
              code={data.code}
              inline
              fallbackIcon={<DefaultIcon />}
            />
            {data.paymentName}
          </SectionInfo>
        </div>
        {data.requisite && (
          <div>
            <SectionLabel>
              {t('shared:тексты.Реквизиты клиента двоеточие')}
            </SectionLabel>
            <SectionInfo>{data.requisite}</SectionInfo>
          </div>
        )}

        {!isEmoney && data.fullName && (
          <div>
            <SectionLabel>
              {t('shared:тексты.ФИО клиента двоеточие')}
            </SectionLabel>
            <SectionInfo>{data.fullName}</SectionInfo>
          </div>
        )}
      </SectionStyled>
    </ModalStyled>
  );
};
