import { useEffect, useState } from 'react';

import { DEAL_EXPIRE_TIME_MINUTES, DEAL_STATUS } from 'shared/constants';
import { TDeals } from 'shared/types';
import BN from 'bignumber.js';
import { useExternalAccountState } from '../../../shared/state/useExternalAccountState';

export enum DEAL_ACTION {
  ACCEPT_DEAL = 'ACCEPT_DEAL',
  TOP_UP = 'TOP_UP',
  CONFIRM_PAYMENT_RECEIVED = 'CONFIRM_PAYMENT_RECEIVED',
  CONFIRM_PAYMENT_SENT = 'CONFIRM_PAYMENT_SENT',
  OPEN_DISPUTE = 'OPEN_DISPUTE',
  CANCEL_DEAL = 'CANCEL_DEAL',
  COMPLAINT = 'COMPLAINT',
  PAY = 'PAY',
}

export interface IAction {
  type: DEAL_ACTION;
  disabled?: boolean;
}

export const useDealActions = (deal: TDeals) => {
  const [dealActions, setDealActions] = useState<IAction[]>([]);

  const externalAccountBalance = useExternalAccountState(
    (state) => state.externalAccountBalance
  );

  useEffect(() => {
    // TODO: в теории тут можно вставить return early если текущий статус не предусматривает действий со стороны пользователя
    // Например: [cancelled, expired, on_argument]

    const isBuying = deal.offer?.is_buying_crypto;
    const isGuarantee = deal.offer?.is_guarantee;
    const allowedStatuses = new Set(deal.allowed_statuses);
    const isCash = deal?.offer?.type === 'cash';
    const currentStatus = deal.status;
    const actions: IAction[] = [];

    // Accept deal
    if (allowedStatuses.has(DEAL_STATUS.accepted)) {
      actions.push({
        type: DEAL_ACTION.ACCEPT_DEAL,
      });
    }

    // Pay in
    // warranty collateral зависает, если не хватает баланса
    if (!isBuying && isGuarantee && currentStatus === DEAL_STATUS.warrantyCollateral) {
      actions.push({
        type: DEAL_ACTION.TOP_UP,
      });
    }

    // Pay in
    // Вика: Добавила для без гаранта (если хватает/не хватает баланса на счете)
    if (
      !isBuying &&
      !isGuarantee &&
      currentStatus === DEAL_STATUS.waitingForCryptoDelivery
    ) {
      const isEnoughBalance = BN(externalAccountBalance || 0).isGreaterThanOrEqualTo(deal.crypto_amount)

      if (isEnoughBalance) {
        actions.push({
          type: DEAL_ACTION.PAY,
        });
      } else {
        actions.push({
          type: DEAL_ACTION.TOP_UP,
        });
      }
    }

    // Confirm receiving of payment
    // EugeneB: Тут добавил !isBuying
    // Получил оплату экшон существует только с гарантом
    if (
      isGuarantee &&
      !isBuying &&
      allowedStatuses.has(DEAL_STATUS.completed)
    ) {
      actions.push({
        type: DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED,
      });
    }

    // Confirm payment sent
    // EugeneB: Добавил условие по статусу
    if (
      (allowedStatuses.has(DEAL_STATUS.ready) &&
        // TODO: проверить нужно ли
        // isBuying &&
        currentStatus === DEAL_STATUS.waitingForFiatPayment) ||
      (!isCash && allowedStatuses.has(DEAL_STATUS.waitingForFiatReceiving))
    ) {
      actions.push({ type: DEAL_ACTION.CONFIRM_PAYMENT_SENT });
    }

    // Open dispute
    if (allowedStatuses.has(DEAL_STATUS.onArgument)) {
      actions.push({
        type: DEAL_ACTION.OPEN_DISPUTE,
      });
    }

    // Cancel deal
    // EugeneVityaVika: Добавили условие по статусу
    {
      const isCancellable =
        allowedStatuses.has(DEAL_STATUS.expired) ||
        allowedStatuses.has(DEAL_STATUS.cancelled);
      // Всегда действующая
      if (isCancellable) {
        actions.push({
          type: DEAL_ACTION.CANCEL_DEAL,
        });
      } else if (isGuarantee) {
        // Всегда выключенная с гарантом
        if (
          (!isCash && currentStatus === DEAL_STATUS.waitingForFiatPayment) ||
          (isCash && !isBuying && currentStatus === DEAL_STATUS.ready)
        ) {
          actions.push({
            type: DEAL_ACTION.CANCEL_DEAL,
            disabled: true,
          });
        }
      } else if (!isGuarantee) {
        // Всегда выключенная с гарантом
        const exchangeDate = deal.exchange_datetime
          ? new Date(deal.exchange_datetime).getTime()
          : 0;
        const currentTime = new Date().getTime();
        const lessThanHalfHour =
          (currentTime - exchangeDate) / (1000 * 60) < DEAL_EXPIRE_TIME_MINUTES;

        const isShowCancelDisabled = () => {
          const conditions = [
            isCash && currentStatus === DEAL_STATUS.waitingForCryptoDelivery,
            !isCash &&
              !isBuying &&
              (currentStatus === DEAL_STATUS.waitingForFiatDelivery ||
                currentStatus === DEAL_STATUS.waitingForCryptoDelivery),
            !isCash &&
              isBuying &&
              currentStatus === DEAL_STATUS.waitingForCryptoDelivery,
          ];

          if (lessThanHalfHour) {
            return conditions.some(Boolean);
          }
        };

        if (isShowCancelDisabled()) {
          actions.push({
            type: DEAL_ACTION.CANCEL_DEAL,
            disabled: true,
          });
        }
      }
    }

    // Complaint = пожаловаться
    if (allowedStatuses.has(DEAL_STATUS.complaint)) {
      actions.push({
        type: DEAL_ACTION.COMPLAINT,
      });
    }

    setDealActions(actions);
  }, [deal, externalAccountBalance]);

  return dealActions;
};
