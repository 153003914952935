import React, { useEffect, useState } from 'react';
import { Grid, Layout as AntdLayout } from 'antd';
import type { RefineLayoutLayoutProps } from '@pankod/refine-ui-types';
import { Header as DefaultHeader } from './header';
import { Sidebar } from './sidebar';
import { ExchangePointsProvider } from '../../contexts/exchange-points-context';
import { useResize } from '../../hooks/useResize';
import { useLocation } from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';
import CheckSession from '../../../app/CheckSession';
import { useCookiesCustom } from '../../hooks/useCookiesCustom';
import { CheckAccessToPage } from 'app/CheckAccessToPage';
import {
  InfoMessage,
  InfoWrapper,
  StyledAntdLayout,
  StyledDiv,
  WhiteLayout,
  WrapperTitleNotification,
} from './layout.styles';
import { useCentrifugeState } from '../../state/centrifuge/useCentrifugeState';
import { notification } from '@pankod/refine-antd';
import { InfoCircleFilled } from '@ant-design/icons';
import './style.css';
import { useUserState } from '../../state/useUserState';

export const Layout: React.FC<
  RefineLayoutLayoutProps & {
    noPadding?: boolean;
  }
> = ({ children, Header, Sider, Footer, OffLayoutArea, noPadding = false }) => {
  const SiderToRender = () => {
    return <Sidebar />;
  };

  const HeaderToRender = Header ?? DefaultHeader;

  const { t, i18n } = useTranslation(['shared']);
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;
  const { isScreen1300 } = useResize();
  const [isPortrait, setIsPortrait] = useState(false);
  const { pathname } = useLocation();
  const { cookie, removeCookie } = useCookiesCustom();

  const companyName = useUserState((state) => state.userApi.company.name);

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const createCentrifugeInstance = useCentrifugeState(
    (state) => state.createCentrifugeInstance
  );

  const connectToCentrifugeInstance = useCentrifugeState(
    (state) => state.connectToCentrifugeInstance
  );

  const disconnectCentrifugeInstance = useCentrifugeState(
    (state) => state.disconnectCentrifugeInstance
  );

  useEffect(() => {
    if (cookie['inviteNotificationShown']) {
      notification.info({
        icon: <InfoCircleFilled style={{ color: '#1677FF' }} />,
        message: (
          <WrapperTitleNotification>
            {t('Вы приступаете к работе в компании')} {companyName}
          </WrapperTitleNotification>
        ),
      });
      removeCookie('inviteNotificationShown');
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(cookie['i18nLang']);
  }, [cookie['i18nLang']]);

  const checkOrientation = () => {
    const isPortraitOrientation =
      window.screen.orientation.type === 'portrait-primary' ||
      window.screen.orientation.type === 'portrait-secondary';
    if (
      pathname.includes('/create-company') ||
      pathname.includes('/create-company-error') ||
      pathname.includes('/login') ||
      localStorage.getItem('creatingCompany') ||
      pathname === '/'
    ) {
      return;
    }
    setIsPortrait(isPortraitOrientation);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener('orientationchange', checkOrientation);

    return () => {
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [pathname]);

  useEffect(() => {
    createCentrifugeInstance();
    return () => {
      disconnectCentrifugeInstance();
    };
  }, []);

  useEffect(() => {
    if (centrifugeInstance) {
      connectToCentrifugeInstance();
    }
  }, [centrifugeInstance]);

  return (
    <CheckSession>
      <CheckAccessToPage>
        {isPortrait && (
          <InfoWrapper>
            <InfoMessage>
              {t('shared:ошибки.Пожалуйста, измените ориентацию на альбомную')}
            </InfoMessage>
          </InfoWrapper>
        )}
        <ExchangePointsProvider>
          <StyledAntdLayout>
            <>{SiderToRender()}</>
            <WhiteLayout>
              <HeaderToRender />
              <AntdLayout.Content>
                <StyledDiv
                  noPadding={noPadding}
                  isSmall={isSmall}
                  isScreen1300={isScreen1300}
                >
                  {children}
                </StyledDiv>
                {OffLayoutArea && <OffLayoutArea />}
              </AntdLayout.Content>
              {Footer && <Footer />}
            </WhiteLayout>
          </StyledAntdLayout>
        </ExchangePointsProvider>
      </CheckAccessToPage>
    </CheckSession>
  );
};
