import { useTranslation } from 'react-i18next';
import { Link } from '@pankod/refine-react-router-v6';
import { Breadcrumb } from 'antd';
import { useMemo } from 'react';

interface IProps {
  isBuyingCrypto: boolean;
  noGuarantee: boolean;
  currencyKey: string;
}

const BreadcrumbRequestPage = ({
  isBuyingCrypto,
  noGuarantee,
  currencyKey,
}: IProps) => {
  const { t, i18n } = useTranslation(['offers', 'shared']);
  const items = useMemo(
    () => [
      {
        title: (
          <Link
            to={noGuarantee ? '/no-guarantee-transactions' : '/transactions'}
          >
            {noGuarantee
              ? t('shared:тексты.Сделки стороннего обмена')
              : t('shared:тексты.Сделки с гарантом')}
          </Link>
        ),
        key: 'transactions',
      },
      {
        title: isBuyingCrypto
          ? t('shared:тексты.Покупка {{currency}}', {
              currency: currencyKey,
            })
          : t('shared:тексты.Продажа {{currency}}', {
              currency: currencyKey,
            }),
        key: 'request',
      },
    ],
    [i18n.language, isBuyingCrypto, noGuarantee, currencyKey]
  );

  return <Breadcrumb items={items} />;
};

export default BreadcrumbRequestPage;
