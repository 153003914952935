import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useModalState } from 'shared/components/ui/RenderModal/model/useModalState';
import { ModalKeys } from 'shared/components/ui/RenderModal/model/enums';
import { AdaptiveFullScreenLoader } from 'shared/components/full-screen-loader';
import { RenderModal } from 'shared/components/ui/RenderModal';
import { OffersTable } from 'features/OffersTable';
import { useOffersState } from '../model/useOffersState';
import { TActiveFilter } from '../model/filters.types';
import { OfferFilters } from 'features/OfferFilters';
import { getCurrencies } from 'shared/api/getCurrencies';
import { NavigationTabs } from './NavigationTabs';
import CreateOfferBtn from './CreateOfferBtn';
import { fetchOffers } from '../../../shared/api/getOffers';
import { getExchangePoints } from 'shared/api/getExchangePoints';
import { TCurrency } from 'shared/types';
import { areArraysEqual } from 'shared/helpers/areArraysEqual';

import { ContainerStyled, HeaderStyled, TitleStyled } from './Offers.styles';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { EmptyTableMessage } from 'shared/components/EmptyTableMessage/EmptyTableMessage';

const OffersPage = ({ noGuarantee }: { noGuarantee?: boolean }) => {
  const { t } = useTranslation(['offers', 'shared']);
  const {
    directionFilters,
    pointsFilters,
    ccyCurrFilters,
    fiatCurrFilters,
    statusFilters,
    setFilter,
    setFilterInitial,
    resetAll,
    setCost,
    setFilterCollapsed,
    setIsCollapsed,
    isFixCost,
    isFiltersCollapsed,
    meta,
    setMeta,
    offers,
    points,
    activeFilters,
    recalcActiveFilters,
    offersType,
    setOffersType,
  } = useOffersState();

  const [isLoading, setIsLoading] = useState(false);
  const [cryptoCurrencies, setCryptoCurrencies] = useState<TCurrency[]>([]);
  const previousOffersState = useRef<TActiveFilter[]>([]);

  const { openModal } = useModalState();

  const handleOpenModalCancel = useCallback(
    (id: number, isBuyingCrypto: boolean, cryptoCurrencyCode: string) => {
      const currency = cryptoCurrencies.find(
        ({ code }) => code === cryptoCurrencyCode
      );
      const currencyCode = currency?.currency_key;

      openModal(ModalKeys.ModalCancelOffer, {
        id: id,
        isBuyingCrypto: isBuyingCrypto,
        cryptoCurrencyCode: currencyCode,
        callback: fetchOffers,
      });
    },
    [cryptoCurrencies, openModal, fetchOffers]
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const data = await Promise.all([getCurrencies(), getExchangePoints()]);

        setCryptoCurrencies(data[0][1].arr);
        setFilterInitial(data.flat());
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setOffersType(noGuarantee ? 'withoutGuarantee' : 'withGuarantee');
  }, [noGuarantee, setOffersType]);

  useEffect(() => void fetchOffers(), [offersType, meta.current_page]);

  useEffect(() => {
    if (areArraysEqual(previousOffersState.current, activeFilters)) {
      return;
    }

    previousOffersState.current = activeFilters;

    void fetchOffers();
  }, [activeFilters]);

  useEffect(() => {
    recalcActiveFilters();
  }, [
    directionFilters,
    pointsFilters,
    ccyCurrFilters,
    fiatCurrFilters,
    statusFilters,
    isFixCost,
  ]);

  const metaProp = useMemo(
    () => ({
      current: meta.current_page,
      total: meta.total,
      pageSize: meta.per_page,
    }),
    [meta]
  );

  const isOffersEmpty = !offers.length;

  const renderEmptyTableMessage = () => {
    if (isOffersEmpty && activeFilters.length <= 1) {
      return (
        <EmptyTableMessage
          text={t('shared:тексты.У вас пока нет офферов')}
          subtext={t(
            'shared:тексты.Данные будут доступны после публикации первого оффера'
          )}
          icon={<FileTextOutlined size={80} />}
        />
      );
    } else {
      return (
        <EmptyTableMessage
          text={t('shared:тексты.Не удалось найти то запятая что вы искали')}
          subtext={t('shared:тексты.Попробуйте изменить или очистить фильтры')}
          icon={<SearchOutlined size={80} />}
        />
      );
    }
  };

  return (
    <ContainerStyled>
      <HeaderStyled>
        <TitleStyled>
          {noGuarantee
            ? t('shared:тексты.Офферы по стороннему обмену')
            : t('shared:тексты.Офферы по сделкам с гарантом')}
        </TitleStyled>
        <CreateOfferBtn />
      </HeaderStyled>
      <NavigationTabs
        setIsCollapsed={setIsCollapsed}
        isFiltersCollapsed={isFiltersCollapsed}
      />
      {isLoading ? (
        <AdaptiveFullScreenLoader />
      ) : (
        <>
          {!(isOffersEmpty && activeFilters.length <= 1) && (
            <OfferFilters
              isFiltersCollapsed={false}
              filters={[
                {
                  options: directionFilters,
                  nameDefaultProp: t('shared:тексты.Все направления'),
                  label: t('shared:тексты.Направление оффера'),
                  type: 'directionFilters',
                },
                {
                  options: statusFilters,
                  nameDefaultProp: t('shared:тексты.Все статусы'),
                  label: t('shared:тексты.Статус оффера'),
                  type: 'statusFilters',
                },
                {
                  options: ccyCurrFilters,
                  label: t('shared:тексты.Актив'),
                  type: 'ccyCurrFilters',
                  isDisabled: true,
                },
                {
                  options: fiatCurrFilters,
                  nameDefaultProp: t('shared:тексты.Все валюты'),
                  label: t('shared:тексты.Фиат'),
                  type: 'fiatCurrFilters',
                  search: true,
                },
                {
                  options: pointsFilters,
                  nameDefaultProp: t('shared:тексты.Все пункты'),
                  label: t('shared:тексты.Пункты обмена'),
                  type: 'pointsFilters',
                  isMultiple: true,
                  search: true,
                },
              ]}
              setFilter={setFilter}
              resetAll={resetAll}
              checkboxes={[
                {
                  name: t('shared:тексты.Без фикс точка стоимости'),
                  value: isFixCost,
                  onChange: setCost,
                },
              ]}
              setFilterCollapsed={setFilterCollapsed}
            />
          )}
          {isOffersEmpty ? (
            renderEmptyTableMessage()
          ) : (
            <OffersTable
              pagination={metaProp}
              setPagination={setMeta}
              offers={offers}
              points={points}
              callbacks={{
                onCancel: handleOpenModalCancel,
              }}
              isOffersEmpty={!offers.length}
            />
          )}
        </>
      )}

      <RenderModal currentModalKey={ModalKeys.ModalCancelOffer} />
    </ContainerStyled>
  );
};
export default OffersPage;
