import { axios } from '../exios';
import { API_URL } from '../../packages/keycloak-client/constants';
import {
  TOffers,
  TOffersData,
  TOffersResponse,
} from '../../pages/offers/model/types';
import { IMeta, OffersGuaranteeType } from '../../interfaces';
import { useOffersState } from '../../pages/offers/model/useOffersState';
import { TActiveFilter } from '../../pages/offers/model/filters.types';

function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, (match) => match[1].toUpperCase());
}

function toCamelCaseArray(arr: TOffersResponse[]): TOffers[] {
  return arr.map((obj) => {
    const newObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelKey = snakeToCamel(key);
        newObj[camelKey] = obj[key as keyof TOffersResponse]; // Здесь мы не углубляемся внутрь значений
      }
    }
    return newObj;
  });
}

export const fetchOffers = async () => {
  const { activeFilters, meta, offersType } = useOffersState.getState();

  const dataOffers = await getOffers(
    activeFilters,
    meta.current_page,
    offersType
  );

  useOffersState.getState().setOffers(dataOffers);
};

export const getOffers = async (
  filters?: TActiveFilter[],
  page?: number,
  offersType?: OffersGuaranteeType | null
): Promise<TOffersData> => {
  try {
    const { data } = await axios.post<{
      data: TOffersResponse[];
      meta: IMeta;
    }>(`${API_URL}/exchange-point-offers/search`, {
      filters: filters,
      sort: [{ field: 'created_at', direction: 'desc' }],
      limit: 10,
      page: page ?? 1,
      ...(offersType ? { scopes: [{ name: offersType }] } : {}),
      includes: [
        {
          relation: 'requisites.currency',
        },
      ],
    });
    const normalizedData: TOffersData = {
      data: toCamelCaseArray(data.data),
      meta: data.meta,
    };
    return normalizedData;
  } catch {
    return {
      data: [],
      meta: {} as IMeta,
    };
  }
};
