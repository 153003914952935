import { CountdownStyled, TimerWrapper } from './RequestInfo.styles';
import { useTranslation } from 'react-i18next';

interface IProps {
  deadline: string | number;
  isShowZeroTimer?: boolean;
}

const Timer = ({ deadline, isShowZeroTimer }: IProps) => {
  const { t } = useTranslation('shared');

  const displayTime = isShowZeroTimer ? 0 : deadline;

  return (
    <TimerWrapper>
      <CountdownStyled
        value={displayTime}
        format={`DD ${t('shared:тексты.д')} : HH ${t('shared:тексты.ч')} : mm`}
        suffix={t('shared:тексты.м')}
      />
    </TimerWrapper>
  );
};

export default Timer;
