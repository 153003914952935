import { API_URL } from '../../../packages/keycloak-client/constants';
import { IMeta } from '../../../interfaces';
import { requestWithCache } from '../../../shared/helpers/requestWithCache';
import { TClients } from '../model/filters.types';
import { TDeals } from 'shared/types';

export const getClients = async (): Promise<TClients> => {
  try {
    // TODO: порефачить, переделав запрос на select distinct
    const { data }: {
      data: TDeals[];
      meta: IMeta;
    } = await requestWithCache(`${API_URL}/exchange-point-offer-requests/search`, {
      method: 'POST',
      data: {
        limit: 10000,
        page: 1,
    }
  });

    const clients = data?.map((el) => el.nickname as string);

    const uniqueClients = clients.filter((value, index, self) => {
      return self.indexOf(value) === index && !!value;
    });

    return { arr: uniqueClients, type: 'clients' };
  } catch {
    return { arr: [], type: 'clients' };
  }
};
