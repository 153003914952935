import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Draft, produce } from 'immer';

import {
  TActiveFilter,
  TFiltersData,
  TFilterSimple,
  TFilterSimpleWithId,
} from 'pages/offers/model/filters.types';
import { getDeals } from 'shared/api/getDeals';
import { DEAL_STATUS } from '../../../shared/constants';
import { IMeta, IExchangePoint, GuaranteeType } from 'interfaces';
import { TDealsData, TFilterSetType } from './types';
import { TDeals } from 'shared/types';

interface IDealsState {
  isFiltersCollapsed: boolean;
  directionFilters: TFilterSimple[];
  statusFilters: TFilterSimpleWithId[];
  fiatCurrFilters: TFilterSimple[];
  ccyCurrFilters: TFilterSimple[];
  clientsFilters: TFilterSimpleWithId[];
  accountsFilters: TFilterSimpleWithId[];
  pointsFilters: TFilterSimpleWithId[];
  resetAll: () => void;
  setFilterInitial: (data: TFiltersData[]) => void;
  setFilter: (values: number[] | string, type: string) => void;
  setFilterCollapsed: (values: number | string, type: string) => void;
  setIsCollapsed: (value: boolean) => void;
  fetchDeals: () => Promise<void>;
  setDeals: (deals: TDealsData) => void;
  deals: TDeals[];
  setMeta: (value: number) => void;
  meta: IMeta;
  points: IExchangePoint[];
  recalcActiveFilters: () => void;
  activeFilters: TActiveFilter[];
  dealsType: GuaranteeType | null;
  setDealsType: (type: GuaranteeType | null) => void;
}

const FILTER_KEYS = new Set<TFilterSetType>([
  'accountsFilters',
  'clientsFilters',
  'ccyCurrFilters',
  'directionFilters',
  'fiatCurrFilters',
  'pointsFilters',
  'statusFilters',
]);

const typeIsValid = (type: string): type is TFilterSetType => {
  return FILTER_KEYS.has(type as TFilterSetType);
};

export const useDealsState = create<IDealsState>()(
  devtools((set, get) => ({
    deals: [],
    meta: {} as IMeta,
    activeFilters: [],
    points: [],
    isFiltersCollapsed: true,
    directionFilters: [
      {
        name: 'buy',
        label: 'Покупка',
        t: 'shared:тексты.',
        checked: false,
      },
      {
        name: 'sell',
        label: 'Продажа',
        t: 'shared:тексты.',
        checked: false,
      },
    ],
    statusFilters: [
      {
        id: 1,
        name: 'pending',
        label: 'На рассмотрении',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 2,
        name: 'accepted',
        label: 'Принята',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 3,
        name: 'cancelled',
        label: 'Отменена',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 4,
        name: 'warranty_collateral',
        label: 'Обеспечение гаранта',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 5,
        name: 'waiting_for_fiat_payment',
        label: 'Ожидание оплаты фиатом',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 6,
        name: 'processing',
        label: 'Обработка транзакции',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 7,
        name: 'ready',
        label: 'Готова к обмену',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 8,
        name: 'expired',
        label: 'Просрочена',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 9,
        name: 'on_argument',
        label: 'Открыт спор',
        t: 'shared:статусы.',
        checked: false,
      },
      {
        id: 10,
        name: 'completed',
        label: 'Выполнена',
        t: 'shared:статусы.',
        checked: false,
      },
    ],
    clientsFilters: [],
    accountsFilters: [],
    pointsFilters: [],
    fiatCurrFilters: [],
    ccyCurrFilters: [
      {
        name: 'USDTTRC20',
        checked: true,
        label: 'USDT',
      },
    ],
    dealsType: null,
    setDealsType: (type) => {
      const previous = get().dealsType;
      if (previous !== type) {
        get().setMeta(1);
        get().resetAll();
      }
      set(
        produce((draft: Draft<IDealsState>) => {
          draft.dealsType = type;
        }),
        false,
        {
          type: 'useDealsState => setDealsType',
        }
      );
    },
    resetAll: () =>
      set(
        produce((draft: Draft<IDealsState>) => {
          draft.directionFilters = draft.directionFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.statusFilters = draft.statusFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.pointsFilters = draft.pointsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.fiatCurrFilters = draft.fiatCurrFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.clientsFilters = draft.clientsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
          draft.accountsFilters = draft.accountsFilters.map((el) => ({
            ...el,
            checked: false,
          }));
        }),
        false,
        {
          type: 'useDealsState => resetAll',
        }
      ),
    setFilterInitial: (data) =>
      set(
        produce((draft: Draft<IDealsState>) => {
          data?.forEach((obj) => {
            if (obj.type === 'clients') {
              draft.clientsFilters = obj?.arr?.map((client) => {
                const filter = draft.clientsFilters.find(
                  (item) => item.name === client
                );
                if (filter) return filter;
                return {
                  id: Math.random(),
                  name: client,
                  label: client,
                  checked: false,
                };
              });
            }

            if (obj.type === 'points') {
              draft.points = obj.arr;
              draft.pointsFilters = obj?.arr?.map((point) => ({
                id: point.id,
                name: point.name,
                label: point.name,
                checked: false,
              }));
            }
            if (obj.type === 'fiat') {
              draft.fiatCurrFilters = obj.arr.map((feat) => ({
                name: feat.currency_key,
                label: feat.currency_key,
                checked: false,
              }));
            }

            if (obj.type === 'accounts') {
              draft.accountsFilters = obj?.arr?.map((fiat) => ({
                id: fiat.id,
                name: String(fiat?.id) as string,
                label: fiat?.name as string,
                checked: false,
              }));
            }
          });
        }),
        false,
        {
          type: 'useDealsState => setFilters',
        }
      ),
    setFilter: (value: string | number[], type: string) => {
      if (!typeIsValid(type)) {
        throw new Error('Wrong filter key');
      }

      set(
        produce((draft: Draft<IDealsState>) => {
          if (
            (type === 'pointsFilters' ||
              type === 'statusFilters' ||
              type === 'accountsFilters' ||
              type === 'clientsFilters') &&
            Array.isArray(value)
          ) {
            draft[type] = draft[type].map((elem) => ({
              ...elem,
              checked: value.includes(elem.id),
            }));
          } else if (
            type === 'fiatCurrFilters' ||
            type === 'ccyCurrFilters' ||
            type === 'directionFilters'
          ) {
            draft[type] = draft[type].map((elem) => ({
              ...elem,
              checked: elem.name === value,
            }));
          }
        }),
        false,
        {
          type: 'useDealsState => setFilter',
        }
      );
    },
    setFilterCollapsed: (value: string | number, type: string) => {
      if (!typeIsValid(type)) {
        throw new Error('Wrong filter key');
      }

      set(
        produce((draft: Draft<IDealsState>) => {
          // тут if для удовлетворения ts TFilterSimple | TFilterSimpleWithId
          if (
            type === 'pointsFilters' ||
            type === 'statusFilters' ||
            type === 'accountsFilters' ||
            type === 'clientsFilters'
          ) {
            draft[type] = draft[type].map((elem) => ({
              ...elem,
              checked: elem.name === value ? false : elem.checked,
            }));
          } else if (
            type === 'fiatCurrFilters' ||
            type === 'ccyCurrFilters' ||
            type === 'directionFilters'
          ) {
            draft[type] = draft[type].map((elem) => ({
              ...elem,
              checked: elem.name === value ? false : elem.checked,
            }));
          }
        }),
        false,
        {
          type: 'useDealsState => setFilter',
        }
      );
    },
    setIsCollapsed: (value: boolean) => {
      set({ isFiltersCollapsed: value });
    },
    fetchDeals: async () => {
      const {
        activeFilters,
        meta: { current_page: currentPage },
      } = get();

      const dealsData = await getDeals(activeFilters, currentPage);

      get().setDeals(dealsData);
    },
    setDeals: (deals) =>
      set(
        produce((draft) => {
          draft.deals = deals.data;
          draft.meta = {
            ...draft.meta,
            total: deals.meta.total,
            last_page: deals.meta.last_page,
          };
        })
      ),
    setMeta: (pagination) =>
      set(
        produce((draft) => {
          draft.meta = { ...draft.meta, current_page: pagination };
        })
      ),
    recalcActiveFilters: () =>
      set(
        produce((draft: Draft<IDealsState>) => {
          let arr: any = [];
          const ccy = draft.ccyCurrFilters.find((cur) => cur.checked);
          if (ccy) {
            arr.push({
              field: 'offer.crypto_currency_code',
              operator: '=',
              value: ccy.name,
            });
          }
          const cash = draft.fiatCurrFilters.find((cur) => cur.checked);
          if (cash) {
            arr.push({
              field: 'offer.cash_currency_code',
              operator: '=',
              value: cash.name,
            });
          }

          const status = draft.statusFilters
            .filter((st: { checked: boolean; name: string }) => {
              return st.checked;
            })
            .map((st: { name: string }) => st.name);

          if (status.length) {
            const processing_filters = [
              DEAL_STATUS.processingExpiration,
              DEAL_STATUS.processingCancellation,
              DEAL_STATUS.processingCompletion,
              DEAL_STATUS.processingFiatDelivery,
              DEAL_STATUS.processingHold,
              DEAL_STATUS.processingHoldFiatPayment,
            ];
            arr.push({ field: 'status', operator: 'in', value: status });
            arr = arr.map((el: any) => {
              if (
                el.field === 'status' &&
                Array.isArray(el.value) &&
                status.includes(DEAL_STATUS.processing)
              ) {
                return {
                  field: 'status',
                  operator: 'in',
                  value: [...el.value, ...processing_filters],
                };
              } else return el;
            });
          }

          const points = draft.pointsFilters
            .filter((st) => st.checked)
            .map((st) => st.id);
          if (points.length) {
            arr.push({
              field: 'offer.exchange_point_id',
              operator: 'in',
              value: points,
            });
          }
          const direction = draft.directionFilters.find((cur) => cur.checked);
          if (direction) {
            arr.push({
              field: 'offer.is_buying_crypto',
              operator: '=',
              value: direction.name === 'buy',
            });
          }

          const accounts = draft.accountsFilters
            .filter((st) => st.checked)
            .map((st) => st.id);
          if (accounts.length) {
            arr.push({
              field: 'cash_account_id',
              operator: 'in',
              value: accounts,
            });
          }

          const clients = draft.clientsFilters
            .filter((st) => st.checked)
            .map((st) => st.name);
          if (clients.length) {
            arr.push({
              field: 'nickname',
              operator: 'in',
              value: clients,
            });
          }

          draft.meta.current_page = 1;
          draft.activeFilters = arr;
        })
      ),
  }))
);
