import {API_URL} from "packages/keycloak-client/constants";
import { requestWithCache } from 'shared/helpers/requestWithCache';
import { IAccount, TAccounts } from "shared/types/accounts";

/**
 * Запрос счетов компании, опионально отфильтрованных по коду валюты
 * @param shortName 
 * @returns 
 */
export const getAccount = async (shortName?: string): Promise<TAccounts> => {
  try {

    const payload = {
      /* limit: 10000,
      page: 1, */
      filter: [
        {
          field: "currency.shortname",
          operator: "=",
          value: shortName
        }
      ],
      sort: [
        {
          direction: "asc",
          field: "status"
        },
        {
          direction: "asc",
          field: "shortname"
        }
      ]
    }

    if (!shortName) {
      payload.filter = [
        {
          field: 'currency.type',
          operator: '=',
          value: 'fiat'
        }
      ]
    }

    // TODO: переделать тут устаноку кэша
    const {data}: { data: IAccount[] } = await requestWithCache(`${API_URL}/accounts/search`, {method: 'POST', data: payload });

    return { arr: data, type: 'accounts' }

  } catch {
    return { arr: [], type: 'accounts' }
  }
}
