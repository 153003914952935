import { ButtonLinkStyled } from './OfferRequestConfirm.styles';
import React from 'react';
import { useNavigation } from '@pankod/refine-core';

interface IButtonLink {
  offerRequestName: string;
  offerRequestId: string;
  isGuarantee: boolean;
}

const ButtonLink = ({
  offerRequestName,
  offerRequestId,
  isGuarantee,
}: IButtonLink) => {
  const { push } = useNavigation();
  return (
    <ButtonLinkStyled
      type={'link'}
      onClick={() =>
        push(
          `/${
            isGuarantee ? 'transactions' : 'no-guarantee-transactions'
          }/request/${offerRequestId}`
        )
      }
    >
      {offerRequestName}
    </ButtonLinkStyled>
  );
};

export default ButtonLink;
