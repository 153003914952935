import { useEffect, useState } from 'react';
import { OffersTable } from 'features/OffersTable';
import { AdaptiveFullScreenLoader } from 'shared/components/full-screen-loader';
import { useModalState } from 'shared/components/ui/RenderModal/model/useModalState';
import { ModalKeys } from 'shared/components/ui/RenderModal/model/enums';
import { LoaderWrapper, TableWrapper } from '../PaymentMethodPage.styles';
import { useTransactionsState } from '../../model/useTransactionsState';
import { toCamelCaseArray } from 'shared/helpers/toCamelCase';
import { getOffers } from 'shared/api/getOffers';

export const OffersTab = ({ requisiteName }: { requisiteName: string }) => {
  const { offersMeta, setOffersMeta, offers, points, setOffers } =
    useTransactionsState();
  const { openModal } = useModalState();
  const [forceOffers, setForceOffers] = useState(false);
  const [isOffersEmpty, setIsOffersEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleForce = () => {
    setForceOffers((prev) => !prev);
  };
  const handleOpenModalCancel = (id: number, isBuyingCrypto: boolean) => {
    openModal(ModalKeys.ModalCancelOffer, {
      id: id,
      isBuyingCrypto: isBuyingCrypto,
      callback: handleForce,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const dataOffers = await getOffers(
          [
            {
              field: 'requisites.currency.name',
              operator: '=',
              value: requisiteName,
            },
          ],
          offersMeta.current_page
        );
        setOffers({
          data: toCamelCaseArray(dataOffers.data),
          meta: dataOffers?.meta,
        });
        if (!dataOffers?.data?.length) {
          setIsOffersEmpty(true);
        }
        if (dataOffers?.data?.length !== 0) {
          setIsOffersEmpty(false);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [offersMeta.current_page, forceOffers]);

  return (
    <TableWrapper>
      {isLoading ? (
        <LoaderWrapper>
          <AdaptiveFullScreenLoader />
        </LoaderWrapper>
      ) : (
        <OffersTable
          pagination={{
            current: offersMeta.current_page,
            total: offersMeta.total,
            pageSize: offersMeta.per_page,
          }}
          setPagination={setOffersMeta}
          offers={offers}
          points={points}
          callbacks={{
            onCancel: handleOpenModalCancel,
          }}
          isOffersEmpty={isOffersEmpty}
        />
      )}
    </TableWrapper>
  );
};
