import { TFunction } from 'i18next';
import { FC } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Dropdown } from '@pankod/refine-antd';

import { DEAL_ACTION, IAction, useDealActions } from './useDealActions';
import {
  AcceptButtonInDropdownStyled,
  ButtonInDropdownStyled,
  CancelButtonInDropdownDisabledStyled,
  CancelButtonInDropdownStyled,
} from './DealsTable.styles';
import { useActionsHandler } from './useActionsHandler';
import { TDeals } from 'shared/types';
import { DEAL_STATUS } from 'shared/constants';
import { MoreOutlined } from '../../../shared/components/icons';
import { useTranslation } from 'react-i18next';

const dealActionsToMenuItems = (
  deal: TDeals,
  actions: IAction[],
  actionHandler: (actionType: DEAL_ACTION) => void,
  t: TFunction<['offers', 'shared']>
): ItemType[] => {
  return actions.map(({ type, disabled }) => {
    let key: string = type.toString();
    let label: React.ReactNode | null = null;

    if (type === DEAL_ACTION.ACCEPT_DEAL) {
      label = (
        <AcceptButtonInDropdownStyled
          onClick={() => actionHandler(DEAL_ACTION.ACCEPT_DEAL)}
        >
          <CheckOutlined />
          &nbsp; {t('shared:кнопки.Принять сделку')}
        </AcceptButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.TOP_UP) {
      label = (
        <ButtonInDropdownStyled
          $blueColor
          onClick={(e) => {
            e.stopPropagation();
            actionHandler(DEAL_ACTION.TOP_UP);
          }}
        >
          <WalletOutlined />
          &nbsp; {t('shared:кнопки.Пополнить баланс')}
        </ButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED) {
      label = (
        <AcceptButtonInDropdownStyled
          onClick={() => {
            actionHandler(DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED);
          }}
        >
          <CheckOutlined />
          &nbsp; {t('Получил оплату')}
        </AcceptButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.CONFIRM_PAYMENT_SENT) {
      label = (
        <AcceptButtonInDropdownStyled
          onClick={() => actionHandler(DEAL_ACTION.CONFIRM_PAYMENT_SENT)}
        >
          <CheckOutlined />
          &nbsp; {t('shared:кнопки.Отправил средства')}
        </AcceptButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.PAY) {
      label = (
        <AcceptButtonInDropdownStyled
          onClick={() => actionHandler(DEAL_ACTION.PAY)}
        >
          <WalletOutlined />
          &nbsp; {t('shared:кнопки.Оплатить')}
        </AcceptButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.OPEN_DISPUTE) {
      label = (
        <ButtonInDropdownStyled
          onClick={() => actionHandler(DEAL_ACTION.OPEN_DISPUTE)}
        >
          <ExclamationCircleOutlined />
          &nbsp; {t('Открыть спор')}
        </ButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.COMPLAINT) {
      label = (
        <CancelButtonInDropdownStyled
          onClick={() => actionHandler(DEAL_ACTION.COMPLAINT)}
        >
          <ExclamationCircleOutlined />
          &nbsp; {t('Пожаловаться')}
        </CancelButtonInDropdownStyled>
      );
    } else if (type === DEAL_ACTION.CANCEL_DEAL) {
      if (disabled) {
        key = `${DEAL_ACTION.CANCEL_DEAL}_disabled`;
        label = (
          <CancelButtonInDropdownDisabledStyled
            onClick={(e) => e.stopPropagation()}
          >
            <CloseOutlined />
            &nbsp; {t('Отменить сделку')}
          </CancelButtonInDropdownDisabledStyled>
        );
      } else {
        const isPending = deal.status === DEAL_STATUS.pending;

        return {
          key: DEAL_ACTION.CANCEL_DEAL,
          label: (
            <CancelButtonInDropdownStyled
              onClick={() => actionHandler(DEAL_ACTION.CANCEL_DEAL)}
            >
              <CloseOutlined />
              &nbsp; {isPending ? t('Отклонить сделку') : t('Отменить сделку')}
            </CancelButtonInDropdownStyled>
          ),
        };
      }
    }

    return { key, label };
  });
};

export const DealActionsDropdown: FC<{
  deal: TDeals;
  fetchDeals: () => Promise<void>;
}> = ({ deal, fetchDeals }) => {
  const { t } = useTranslation(['offers', 'shared']);

  const dealActions = useDealActions(deal as TDeals);
  const actionHandler = useActionsHandler(deal as TDeals, fetchDeals);

  if (!dealActions.length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items: dealActionsToMenuItems(
          deal as TDeals,
          dealActions,
          actionHandler,
          t
        ),
      }}
    >
      {MoreOutlined()}
    </Dropdown>
  );
};
