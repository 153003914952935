import {
  RequestInfoHeader,
  RequestInfoWrapper,
  Title,
} from './RequestInfo.styles';
import { Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../../shared/components/styled';
import { Tag } from 'antd';
import Timer from './Timer';
import { IRequest } from '../../model/types';
import RequestInfoItem from './RequestInfoItem';
import dayjs from 'dayjs';
import ActionsBtnBlock from './ActionsBtnBlock';
import { ModalKeys } from '../../../../shared/components/ui/RenderModal/model/enums';
import { useModalState } from '../../../../shared/components/ui/RenderModal/model/useModalState';
import { RenderModal } from '../../../../shared/components/ui/RenderModal';
import { Link, useNavigate } from '@pankod/refine-react-router-v6';
import useFormattedExchangeRequest from 'pages/offer-request/model/hooks/useFormattedExchangeRequest';
import { useFormattedClientRequest } from 'pages/offer-request/model/hooks/useFormattedClientRequest';
import { DEAL_ACTION } from 'features/DealsTable/ui/useDealActions';
import { TDeals } from 'shared/types';
import { useCallback } from 'react';
import { useExternalAccountState } from '../../../../shared/state/useExternalAccountState';
import { useDealStatusLabel } from 'shared/hooks/useDealStatusLabels';
import { EOfferRequestStatusTagColors } from 'shared/enums';

interface IProps {
  request: IRequest;
  refetchRequest: () => Promise<void>;
}

const RequestInfo = ({ request, refetchRequest }: IProps) => {
  const { t } = useTranslation('shared');
  const navigate = useNavigate();
  const { openModal } = useModalState();

  const externalAccountId = useExternalAccountState(
    (state) => state.externalAccountId
  );

  const handleRedirectToConfirm = useCallback(
    (id: number, isGuarantee: boolean) => {
      navigate(
        `/${
          isGuarantee ? 'transactions' : 'no-guarantee-transactions'
        }/confirm-request/${id}`
      );
    },
    [navigate]
  );

  const handleRedirectToAccount = useCallback(() => {
    navigate(`/accounts/${externalAccountId}`);
  }, [externalAccountId, navigate]);

  const actionButtonClickHandler = useCallback(
    (actionType: DEAL_ACTION) => {
      const modalProps: {
        id: number;
        isBuyingCrypto: boolean;
        name?: string;
        status: string;
        currency: string;
        isGuarantee: boolean;
        refetchData: () => Promise<void>;
        deal?: IRequest;
        accountId?: number | undefined;
        isCash?: boolean;
      } = {
        id: request.id,
        isBuyingCrypto: Boolean(request.offer?.is_buying_crypto),
        isGuarantee: Boolean(request.offer?.is_guarantee),
        name: request.name,
        status: request.status,
        currency: 'USDT',
        isCash: request.offer?.type === 'cash',
        refetchData: refetchRequest,
      };

      switch (actionType) {
        case DEAL_ACTION.CONFIRM_PAYMENT_SENT: {
          modalProps.deal = request;
          openModal(ModalKeys.ModalConfirmPaymentSent, modalProps);
          break;
        }
        case DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED: {
          openModal(ModalKeys.ModalConfirmReceiptOfCash, modalProps);
          break;
        }
        case DEAL_ACTION.PAY: {
          modalProps.deal = request;
          openModal(ModalKeys.ModalPay, modalProps);
          break;
        }
        case DEAL_ACTION.CANCEL_DEAL: {
          openModal(ModalKeys.ModalCancelTransaction, modalProps);
          break;
        }
        case DEAL_ACTION.COMPLAINT: {
          modalProps.deal = request;
          openModal(ModalKeys.ModalComplaint, modalProps);
          break;
        }
        case DEAL_ACTION.OPEN_DISPUTE: {
          modalProps.currency = 'USDT';
          openModal(ModalKeys.ModalOpenDispute, modalProps);
          break;
        }
        case DEAL_ACTION.ACCEPT_DEAL: {
          handleRedirectToConfirm(modalProps.id, modalProps.isGuarantee);
          break;
        }
        case DEAL_ACTION.TOP_UP: {
          handleRedirectToAccount();
          break;
        }
      }
    },
    [
      request,
      refetchRequest,
      openModal,
      handleRedirectToConfirm,
      handleRedirectToAccount,
    ]
  );

  const exchangePointBlock = useFormattedExchangeRequest(request);
  const clientBlock = useFormattedClientRequest(request);

  const dealStatusLabel = useDealStatusLabel({
    currentStatus: request.status,
    noGuarantee: !request.offer?.is_guarantee,
    isCash: request.offer?.type === 'cash',
    isBuying: request.offer?.is_buying_crypto,
  });

  const infoTitle = request.offer?.is_buying_crypto
    ? t('shared:тексты.Покупка {{currency}}', {
        currency: request.offer?.crypto_payment_system_currency?.currency_key,
      })
    : t('shared:тексты.Продажа {{currency}}', {
        currency: request.offer?.crypto_payment_system_currency?.currency_key,
      });

  const timerDeadline = dayjs(request?.exchange_datetime ?? 0).toString();
  const isShowZeroTimer = [
    'completed',
    'expired',
    'cancelled',
    'complaint',
  ].includes(request?.status);

  return (
    <RequestInfoWrapper>
      <RequestInfoHeader>
        <Flex align={'flex-start'}>
          <Flex gap={4} justify={'flex-start'} align={'flex-start'} vertical>
            <Flex gap={4} align={'flex-start'} vertical>
              <Flex gap={16} align={'center'}>
                <Title level={3}>{infoTitle}</Title>
                <Tag color={EOfferRequestStatusTagColors[request.status]}>
                  {dealStatusLabel}
                </Tag>
              </Flex>
              <Typography.Text type="secondary">
                #{request?.name}
              </Typography.Text>
            </Flex>
          </Flex>
          <ActionsBtnBlock
            deal={request as TDeals}
            actionHandler={actionButtonClickHandler}
          />
        </Flex>
      </RequestInfoHeader>
      <Flex align={'flex-start'}>
        <RequestInfoItem
          title={
            request?.offer?.is_buying_crypto
              ? t('shared:тексты.Пункт получает')
              : t('shared:тексты.Пункт отдает')
          }
          value={String(
            exchangePointBlock.items.find((item) => item.name === 'cash')!.value
          )}
          strong
        />
        <RequestInfoItem
          title={
            request?.offer?.is_buying_crypto
              ? t('shared:тексты.Клиент получает')
              : t('shared:тексты.Клиент отдает')
          }
          value={clientBlock.items.find((item) => item.name === 'cash')!.value}
          strong
        />
        <RequestInfoItem
          title={t('shared:тексты.Способ оплаты')}
          value={
            request?.offer?.type === 'cash' ? (
              t('shared:тексты.Наличные')
            ) : (
              <Link to={`/payment-methods/${request?.requisite?.id}`}>
                {request?.requisite?.currency.name}
              </Link>
            )
          }
        />
        {request?.status !== 'on_argument' && request?.status !== 'pending' && (
          <Timer deadline={timerDeadline} isShowZeroTimer={isShowZeroTimer} />
        )}
      </Flex>
      {request.status !== 'pending' && request.offer?.type === 'cash' && (
        <Flex>
          <Flex align={'flex-start'} vertical>
            <Typography.Text type="secondary">
              {t('shared:тексты.Место обмена')}
            </Typography.Text>
            <Typography.Text strong>
              {request?.exchange_address ?? '-'}
            </Typography.Text>
          </Flex>
        </Flex>
      )}
      <RenderModal currentModalKey={ModalKeys.ModalPay} />
      <RenderModal currentModalKey={ModalKeys.ModalComplaint} />
      <RenderModal currentModalKey={ModalKeys.ModalConfirmReceiptOfCash} />
      <RenderModal currentModalKey={ModalKeys.ModalConfirmPaymentSent} />
      <RenderModal currentModalKey={ModalKeys.ModalOpenDispute} />
      <RenderModal currentModalKey={ModalKeys.ModalCancelTransaction} />
    </RequestInfoWrapper>
  );
};

export default RequestInfo;
