import styled from 'styled-components';
import { Badge, Row } from '@pankod/refine-antd';
import { Menu, Button } from 'antd';
import {
  MessageOutlined,
  PlaySquareOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

export const SidebarWrapper = styled.div`
  background: #14141f;
  box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
    0 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-right: 1px solid #413d5080;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
`;

export const StyledLogoRow = styled(Row)<{ $isCollapsed?: boolean }>`
  padding: ${(props) =>
    props.$isCollapsed ? '22px 8px 22px 16px' : '20px 30px'};
`;

export const StyledMenu = styled(Menu)<{ $isCollapsed?: boolean }>`
  .ant-menu-submenu {
    .ant-menu-item {
      padding-left: 38px !important;
    }
  }
  .ant-menu-item-icon {
    path {
      fill: white !important;
    }
  }
  .ant-menu-item-selected {
    background: var(--primary-gradient);
    .ant-badge-count {
      color: #1677ff !important;
      background-color: white !important;
    }
  }

  .ant-menu-submenu {
    position: relative;

    &::after {
      display: ${(props) => (props.$isCollapsed ? 'block' : 'none')};
      content: '⌄';
      position: absolute;
      top: 45%;
      left: 60%;
      font-size: 20px;
      transform: translate(100%, -50%) rotateZ(0deg);
      width: 12px;
      height: 15px;
      transition: 0.25s ease;
      pointer-events: none;
    }
    &-active {
      &::after {
        transform: translate(100%, -50%) rotateZ(180deg);
      }
    }
  }
`;

export const StyledBottomMenu = styled(Menu)`
  margin-bottom: 32px;
  color: #ffffffa6;
  .ant-menu-item-icon {
    path {
      fill: #ffffffa6 !important;
    }
  }
`;

export const StyledButton = styled(Button)`
  width: unset !important;
  height: unset !important;
  line-height: unset;
`;

export const SideBarWithMarkOrBadgeStyled = styled.div<{
  $isCollapsed?: boolean;
  $isActive?: boolean;
  $withMark?: boolean;
}>`
  display: ${(props) => (props.$isCollapsed ? 'inline-flex' : 'flex')};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const MarkStyled = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #08979c;
`;

export const CollapsedMarkStyled = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #08979c;
  position: absolute;
  top: 5px;
  left: 40px;
`;

export const BadgeStyled = styled(Badge)<{
  $isActive?: boolean;
}>`
  &&& .ant-badge-count {
    color: white;
    background-color: #08979c;
  }
`;

export const StyledPlaySquareOutlined = styled(PlaySquareOutlined)``;
export const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)``;
export const StyledMessageOutlined = styled(MessageOutlined)``;
