import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DealStatusProps } from 'shared/components/ui/DealStatus/DealsStatus';
import { DEAL_STATUS } from 'shared/constants';
import { TDealsStatus } from 'shared/types';

type TDealLabels = Partial<Record<TDealsStatus, string>>;

const PROCESSING_STATUSES = new Set<TDealsStatus>([
  DEAL_STATUS.processingCancellation,
  DEAL_STATUS.processingCompletion,
  DEAL_STATUS.processingExpiration,
  DEAL_STATUS.processingFiatDelivery,
  DEAL_STATUS.processingHoldFiatPayment,
  DEAL_STATUS.processingHold,
  DEAL_STATUS.processing,
]);

const LABELS = {
  payment: 'Оплата',
  waitingForPayment: 'Ожидание оплаты',
  approvingPayment: 'Подтверждение оплаты',
};

const TRANSACTION_HANDLING = 'Обработка транзакции';

const STATIC_STATUS: TDealLabels = {
  pending: 'На рассмотрении',
  accepted: 'Принята',
  warranty_collateral: 'Обеспечение гаранта',
  waiting_for_fiat_payment: LABELS.waitingForPayment,
  ready: 'Готова к обмену',
  completed: 'Выполнена',
  expired: 'Просрочена',
  on_argument: 'Открыт спор',
  complaint: 'Жалоба',
  cancelled: 'Отменена',
};
// isBuying - ПО ПОКУПАЕТ
export const getStatusLabels = ({
  currentStatus,
  noGuarantee,
  isBuying,
}: DealStatusProps) => {
  const staticStatus = STATIC_STATUS[currentStatus as TDealsStatus];

  if (staticStatus) {
    return staticStatus;
  }

  const isProcessing = PROCESSING_STATUSES.has(currentStatus as TDealsStatus);

  if (isProcessing) {
    return TRANSACTION_HANDLING;
  }

  if (noGuarantee) {
    if (currentStatus === DEAL_STATUS.waitingForCryptoDelivery) {
      return isBuying ? LABELS.waitingForPayment : LABELS.payment;
    } else if (currentStatus === DEAL_STATUS.waitingForFiatDelivery) {
      return isBuying ? LABELS.payment : LABELS.waitingForPayment;
    } else if (currentStatus === DEAL_STATUS.waitingForFiatReceiving) {
      return LABELS.approvingPayment;
    }
  }

  console.error(`Status label was not found: ${currentStatus}`);

  return `Неизвестный статус`;
};

export const useDealStatusLabel = ({
  currentStatus,
  noGuarantee,
  isCash,
  isBuying,
}: DealStatusProps) => {
  const { t } = useTranslation(['shared']);

  const statusLabel = useMemo(() => {
    const labelKey = getStatusLabels({ currentStatus, noGuarantee, isCash, isBuying })

    return t(`статусы.${labelKey}`);
  }, [currentStatus, noGuarantee, isCash, isBuying, t]);

  return statusLabel;
};
