import { useEffect, useState } from 'react';
import { TabsProps } from '@pankod/refine-antd';
import { RequestTabs } from '../OfferRequestPage.styles';
import { Flex } from '../../../../shared/components/styled';
import DatesInfo from './request-history-tab-chilren/date-info/DatesInfo';
import { IRequest } from '../../model/types';
import dayjs from 'dayjs';
import OfferRequestHistoryTable from '../../../../features/offer-request-history-table';
import { useTranslation } from 'react-i18next';

import RequestTabsData from './RequestTabsData';
import useFormattedGeneralRequestInfo from 'pages/offer-request/model/hooks/useFormattedGeneralRequestInfo';
import useFormattedExchangeRequest from 'pages/offer-request/model/hooks/useFormattedExchangeRequest';
import { useFormattedClientRequest } from 'pages/offer-request/model/hooks/useFormattedClientRequest';
import styled from 'styled-components';

const DataContainerStyled = styled.div`
  display: grid;
  gap: 24px;
`;

const LineStyled = styled.hr`
  outline: none;
  border: none;
  height: 2px;
  background: #0000000f;
  margin-left: 24px;
  margin-right: 24px;
`;

interface IProps {
  request: IRequest;
}

const OfferRequestTabs = ({ request }: IProps) => {
  const { t } = useTranslation('shared');
  const formatDate = (date: string) => {
    return dayjs(date).format('DD.MM.YYYY HH:mm');
  };

  const [sortedHistory, setSortedHistory] = useState<IRequest['history']>([]);

  useEffect(() => {
    setSortedHistory(
      (request.history ?? []).slice().sort((a, b) => b.id - a.id)
    );
  }, [request.history]);

  const generalInfo = useFormattedGeneralRequestInfo(request);
  const exchangeInfo = useFormattedExchangeRequest(request);
  const clientInfo = useFormattedClientRequest(request);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('shared:тексты.Подробности'),
      children: (
        <DataContainerStyled>
          <RequestTabsData
            header={t('shared:тексты.О клиенте')}
            items={clientInfo.items.filter((item) => item.name !== 'cash')}
          />
          <LineStyled />
          <RequestTabsData
            header={t('shared:тексты.О пункте обмена')}
            items={exchangeInfo.items.filter((item) => item.name !== 'cash')}
          />
          <LineStyled />
          <RequestTabsData
            header={t('shared:тексты.Общая информация')}
            items={generalInfo.items}
          />
        </DataContainerStyled>
      ),
    },
    {
      key: '2',
      label: t('shared:тексты.История сделки'),
      children: (
        <Flex gap={24} align={'stretch'} vertical>
          <DatesInfo
            dateCreated={formatDate(request?.created_at ?? 0)}
            dateFreezeRate={
              request.exchange_rate_freeze_datetime
                ? formatDate(request.exchange_rate_freeze_datetime)
                : undefined
            }
            dateFreezeExchange={
              request.offer?.is_guarantee && request.exchange_freeze_datetime
                ? formatDate(request.exchange_freeze_datetime)
                : undefined
            }
            dateOverdue={
              request.exchange_datetime
                ? formatDate(request.exchange_datetime)
                : undefined
            }
          />
          <OfferRequestHistoryTable
            history={sortedHistory}
            client={request.client}
            dealStatus={request.status}
            noGuarantee={!request.offer?.is_guarantee}
            isCash={request.offer?.type === 'cash'}
            isBuying={request.offer?.is_buying_crypto}
          />
        </Flex>
      ),
    },
  ];
  return <RequestTabs items={items} />;
};

export default OfferRequestTabs;
