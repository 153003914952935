import styled, { css } from 'styled-components';
import { Card } from '@pankod/refine-antd';
import { Tabs } from 'antd';
import { Flex } from '../../../shared/components/styled';

export const PageWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
`;

export const SpinWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const RequestInfoWrapper = styled(Card)`
  border-radius: 16px;
  overflow: hidden;

  & .ant-card-body {
    padding: 24px 0 0;
  }
`;

export const RequestTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 12px 24px;
    font-weight: 500;
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }
`;

export const DatesInfoWrapper = styled.div<{ $oneColumn: boolean }>`
  width: 100%;
  padding: 8px 16px;

  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  display: grid;
  justify-content: space-between;

  grid-template-columns: auto auto;

  ${(props) =>
    props.$oneColumn
      ? css`
          grid-template-columns: auto;
        `
      : ''}
`;

export const RequestInfoItemWrapper = styled(Flex)`
  flex: 1;
`;
