import React, { useState } from 'react';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import { axios } from '../../../../../exios';
import { ModalKeys } from '../../model/enums';
import { Modal, Input } from 'antd';
import { useModalState } from '../../model/useModalState';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { Button, notification } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { Typography } from '@pankod/refine-antd';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  HelperTextStyled,
  ModalBodyStyled,
  ModalTitleStyled,
  TextareaStyled,
} from './ModalComplaint.styles';

export const ModalComplaint = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const CommentLengthErrorText = t('Введите более 10 тире ти символов');

  const [commentValue, setCommentValue] = useState<string>('');
  const [errorText, setErrorText] = useState<string | null>(null);

  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalComplaint)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalComplaint).options
  );

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalComplaint);
    navigate(
      `/${
        modalOptions?.isGuarantee ? 'transactions' : 'no-guarantee-transactions'
      }/request/${modalOptions.id}`
    );
  };

  const validateComment = () => {
    if (commentValue.trim().length <= 10) {
      setErrorText(CommentLengthErrorText);
      return false;
    }
    return true;
  };

  const handleCommentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorText(null);
    setCommentValue(e.target.value);
  };

  const handleChangeOfferRequestStatus = async () => {
    if (!validateComment()) {
      return;
    }
    try {
      const res = await axios.put(
        `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/complain`,
        {
          reason: commentValue,
        }
      );
      if (res) {
        modalOptions?.refetchData();
        notification.info({
          message: t('Жалоба отправлена'),
          icon: <InfoCircleFilled style={{ color: 'rgba(22, 119, 255, 1)' }} />,
          description: (
            <div>
              {t('Жалоба на сделку')}
              <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
                {modalOptions.name}
              </ButtonAsLinkStyled>
              {t(
                `на ${
                  modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
                } отправлена точка`,
                {
                  currency: modalOptions.currency,
                }
              )}
            </div>
          ),
          style: {
            borderLeft: '6px solid rgba(22, 119, 255, 1)',
            width: '400px',
          },
        });
      }
    } catch {
      notification.error({
        message: t('shared:тексты.Ошибка запроса'),
        description: t(
          'shared:тексты.Произошла ошибка при выполнении запроса точка'
        ),
      });
    } finally {
      closeModal(ModalKeys.ModalComplaint);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalComplaint);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={636}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled>{t('Подача жалобы')}</ModalTitleStyled>
        <HelperTextStyled>
          {t(
            'После отправки жалобы запятая действия по сделке больше не будут доступны'
          )}
        </HelperTextStyled>
        <CommentBlockStyled>
          <CommentTitleStyled>
            {t('Укажите причину жалобы')} <span>*</span>
          </CommentTitleStyled>
          <TextareaStyled
            onChange={handleCommentChange}
            status={errorText ? 'error' : undefined}
            showCount
            minLength={10}
            maxLength={5000}
            placeholder={String(t('shared:тексты.Введите комментарий'))}
            value={commentValue}
          />
          <Typography.Text type="danger">{errorText}</Typography.Text>
        </CommentBlockStyled>
        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>{t('shared:кнопки.Назад')}</Button>
          <Button onClick={handleChangeOfferRequestStatus} type="primary">
            {t('Пожаловаться')}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};
