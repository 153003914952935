import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { axios } from '../../../../../exios';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  LinkContainerStyled,
  LinkStyled,
  ModalAmountStyled,
  ModalAmountTitleStyled,
  ModalBodyStyled,
  ModalContentWrapperStyled,
  ModalDescriptionStyled,
  ModalRowStyled,
  ModalTitleStyled,
} from './ModalPay.styles';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { TDeals } from '../../../../../types';
import { useExternalAccountState } from '../../../../../state/useExternalAccountState';

export const ModalPay = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const navigate = useNavigate();

  const externalAccountId = useExternalAccountState(
    (state) => state.externalAccountId
  );

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalPay)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalPay).options
  );

  const deal = modalOptions.deal as TDeals;

  const closeModalHandler = () => {
    closeModal(ModalKeys.ModalPay);
  };

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalPay);
    navigate(
      `/${
        modalOptions?.isGuarantee ? 'transactions' : 'no-guarantee-transactions'
      }/request/${modalOptions.id}`
    );
  };

  const navigateToExternalAccountHandler = () => {
    navigate(`/accounts/${externalAccountId}`);
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios
        .put(
          `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/confirm-fiat-receiving`
        )
        .catch((e) => {
          if (
            e.response?.data?.message ===
            'Balance on external account is not enough'
          ) {
            notification.error({
              message: t('Не удалось оплатить'),
              description: (
                <div>
                  <span>
                    {t(
                      'Недостаточно средств точка Пополните баланс счёта запятая чтобы завершить сделку'
                    )}
                  </span>
                  <LinkContainerStyled>
                    <LinkStyled onClick={navigateToExternalAccountHandler}>
                      {t('shared:кнопки.Пополнить баланс')}
                    </LinkStyled>
                  </LinkContainerStyled>
                </div>
              ),
              style: { borderLeft: '6px solid rgba(255, 77, 79, 1)' },
            });
          }
          return;
        })
        .then((res) => {
          if (res) {
            notification.success({
              message: t('Оплата отправлена'),
              icon: (
                <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />
              ),
              description: (
                <div>
                  {t('Оплата по сделке')}
                  <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
                    {modalOptions.name}
                  </ButtonAsLinkStyled>
                  {t(
                    `на ${
                      modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
                    } USDT успешно отправлена точка`
                  )}
                </div>
              ),
              style: {
                borderLeft: '6px solid rgba(19, 194, 194, 1)',
                width: '400px',
              },
            });
          }
        });
      modalOptions?.refetchData();
    } catch {
      console.error('An error occurred while updating the transaction status');
    } finally {
      closeModal(ModalKeys.ModalPay);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalPay);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={closeModalHandler}
      footer={null}
      width={636}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalRowStyled>
          <div>
            <ModalTitleStyled>{t('Оплата')}</ModalTitleStyled>
            <ModalDescriptionStyled>
              {t('Средства будут переведены клиенту')}
            </ModalDescriptionStyled>
          </div>
        </ModalRowStyled>
        <ModalContentWrapperStyled>
          <ModalAmountTitleStyled>{t('Сумма оплаты')}</ModalAmountTitleStyled>
          <ModalAmountStyled>
            {deal?.crypto_amount}{' '}
            {deal.offer?.crypto_payment_system_currency?.currency_key ?? 'USDT'}
          </ModalAmountStyled>
        </ModalContentWrapperStyled>
        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>
            {t('shared:кнопки.Отменить')}
          </Button>
          <Button onClick={handleChangeOfferRequestStatus} type="primary">
            {t('shared:кнопки.Оплатить')}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};
