import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useDealsState } from './model/useDealsState';
import { getCurrencies } from 'shared/api/getCurrencies';
import { getExchangePoints } from 'shared/api/getExchangePoints';
import { areArraysEqual } from 'shared/helpers/areArraysEqual';
import { fetchDeals } from './api/fetchDeals';
import { useTranslation } from 'react-i18next';
import { AdaptiveFullScreenLoader } from 'shared/components/full-screen-loader';
import DealsTable from 'features/DealsTable/ui/DealsTable';
import { OfferFilters } from 'features/OfferFilters';
import { NavigationTabs } from 'pages/offers/ui/NavigationTabs';
import { getClients } from 'pages/offers/api/getClients';
import {
  ContainerStyled,
  HeaderStyled,
  TitleStyled,
} from 'pages/offers/ui/Offers.styles';
import { getAccount } from 'shared/api/getAccount';
import {
  TActiveFilter,
  TFilterSimple,
  TFilterSimpleWithId,
} from 'pages/offers/model/filters.types';
import { InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { EmptyTableMessage } from 'shared/components/EmptyTableMessage/EmptyTableMessage';

interface DealsPageProps {
  noGuarantee?: boolean;
}

interface FilterType {
  options: TFilterSimple[] | TFilterSimpleWithId[];
  nameDefaultProp?: string;
  label: string;
  type: string;
  isMultiple?: boolean;
  search?: boolean;
  isDisabled?: boolean;
}

export const DealsPage: FC<DealsPageProps> = ({ noGuarantee }) => {
  const {
    directionFilters,
    pointsFilters,
    ccyCurrFilters,
    fiatCurrFilters,
    clientsFilters,
    accountsFilters,
    statusFilters,
    setFilter,
    setFilterInitial,
    resetAll,
    setFilterCollapsed,
    setIsCollapsed,
    isFiltersCollapsed,
    meta,
    setMeta,
    deals,
    points,
    activeFilters,
    recalcActiveFilters,
    dealsType,
    setDealsType,
  } = useDealsState();

  const { t } = useTranslation(['offers', 'shared']);
  const [isLoading, setIsLoading] = useState(true);
  const previousFilters = useRef<TActiveFilter[]>([]);

  // Initial load
  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        const data = await Promise.all([
          getCurrencies(),
          getExchangePoints(),
          getClients(),
        ]);

        setFilterInitial(data.flat());
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  /**
   * Handle initial load and filters updates
   */

  useEffect(() => {
    setDealsType(noGuarantee ? 'withoutGuarantee' : 'withGuarantee');
  }, [noGuarantee, setDealsType]);

  useEffect(() => void fetchDeals(), [dealsType, meta.current_page]);

  useEffect(() => {
    if (areArraysEqual(previousFilters.current, activeFilters)) {
      return;
    }

    previousFilters.current = activeFilters;

    void fetchDeals();
  }, [activeFilters]);

  const fiatCodeFilterValue = activeFilters.find(
    (el) => el.field === 'offer.cash_currency_code'
  )?.value;

  useEffect(() => {
    (async () => {
      const dataDeals = await getAccount(fiatCodeFilterValue?.toString());
      setFilterInitial([dataDeals]);
    })();
  }, [fiatCodeFilterValue]);

  useEffect(() => {
    recalcActiveFilters();
  }, [
    directionFilters,
    pointsFilters,
    ccyCurrFilters,
    fiatCurrFilters,
    statusFilters,
    clientsFilters,
    accountsFilters,
  ]);

  const metaProp = useMemo(
    () => ({
      current: meta.current_page,
      total: meta.total,
      pageSize: meta.per_page,
    }),
    [meta]
  );

  const isDealsEmpty = !deals.length;

  const getFilters = (): FilterType[] => {
    const baseFilters = [
      {
        options: directionFilters,
        nameDefaultProp: t('shared:тексты.Все направления'),
        label: t('shared:тексты.Направление оффера'),
        type: 'directionFilters',
      },
      {
        options: pointsFilters,
        nameDefaultProp: t('shared:тексты.Все пункты'),
        label: t('shared:тексты.Пункты обмена'),
        type: 'pointsFilters',
        isMultiple: true,
        search: true,
      },
      {
        options: ccyCurrFilters,
        label: t('shared:тексты.Актив'),
        type: 'ccyCurrFilters',
        isDisabled: true,
      },
      {
        options: clientsFilters,
        nameDefaultProp: t('shared:тексты.Все клиенты'),
        label: t('shared:тексты.Клиент'),
        type: 'clientsFilters',
        isMultiple: true,
        search: true,
      },
      {
        options: fiatCurrFilters,
        nameDefaultProp: t('shared:тексты.Все валюты'),
        label: t('shared:тексты.Фиат'),
        type: 'fiatCurrFilters',
        search: true,
      },
      {
        options: accountsFilters,
        nameDefaultProp: t('shared:тексты.Все счета'),
        label: t('Счет для наличных'),
        type: 'accountsFilters',
        isMultiple: true,
        search: true,
      },
    ];

    if (!noGuarantee) {
      baseFilters.splice(1, 0, {
        options: statusFilters,
        nameDefaultProp: t('shared:тексты.Все статусы'),
        label: t('Статус сделки'),
        type: 'statusFilters',
        isMultiple: true,
        search: true,
      });
    }

    return baseFilters;
  };

  const renderEmptyTableMessage = () => {
    if (isDealsEmpty && activeFilters.length <= 1) {
      return (
        <EmptyTableMessage
          text={t('shared:тексты.У вас пока нет сделок')}
          subtext={t(
            'shared:тексты.Данные будут доступны после тогозапятая как вам предложат сделки по офферам'
          )}
          icon={<InboxOutlined size={80} />}
        />
      );
    } else {
      return (
        <EmptyTableMessage
          text={t('shared:тексты.Не удалось найти то запятая что вы искали')}
          subtext={t('shared:тексты.Попробуйте изменить или очистить фильтры')}
          icon={<SearchOutlined size={80} />}
        />
      );
    }
  };

  return (
    <ContainerStyled>
      <HeaderStyled>
        <TitleStyled>
          {noGuarantee
            ? t('shared:тексты.Сделки стороннего обмена')
            : t('shared:тексты.Сделки с гарантом')}
        </TitleStyled>
      </HeaderStyled>
      <NavigationTabs
        setIsCollapsed={setIsCollapsed}
        isFiltersCollapsed={isFiltersCollapsed}
      />
      {isLoading ? (
        <AdaptiveFullScreenLoader />
      ) : (
        <>
          {!(isDealsEmpty && activeFilters.length <= 1) && (
            <OfferFilters
              isFiltersCollapsed={false}
              filters={getFilters()}
              setFilter={setFilter}
              resetAll={resetAll}
              setFilterCollapsed={setFilterCollapsed}
            />
          )}
          {isDealsEmpty ? (
            renderEmptyTableMessage()
          ) : (
            <DealsTable
              refetchDeals={fetchDeals}
              pagination={metaProp}
              setPagination={setMeta}
              deals={deals}
              points={points}
            />
          )}
        </>
      )}
    </ContainerStyled>
  );
};
