import { Button } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

import { GridRowStyled } from './Offers.styles';
import { DownOutlinedStyled } from './NavigationTabs.styles';
import { TNavigation } from '../model/types';

/**
 * Похоже нужен deprecated, т.к. activeTab всегда 'offers'
 */
interface NavigationTabsProps {
  isFiltersCollapsed: boolean;
  activeTab?: TNavigation
  setIsCollapsed: (value: boolean) => void;
}

export const NavigationTabs = (props: NavigationTabsProps) => {
  const { setIsCollapsed, isFiltersCollapsed, activeTab } = props;
  const { t } = useTranslation(['shared']);

  const handleCollapse = () => {
    setIsCollapsed(!isFiltersCollapsed);
  };

  return (
    <GridRowStyled>
      {activeTab === 'exchange' && (
        <Button
          onClick={handleCollapse}
          icon={
            <DownOutlinedStyled $color={'rgba(0, 0, 0, 0.25)'} $rotate={true} />
          }
          type="text"
        >
          {t('shared:тексты.Все фильтры')}
        </Button>
      )}
    </GridRowStyled>
  );
};
