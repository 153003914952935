import { ChatContainerStyled } from './Chat.styles';
import { ChatHeader } from './ChatHeader';
import { ChatBody } from './ChatBody';
import { ChatFooter } from './ChatFooter';
import { IRequest } from '../../../../pages/offer-request/model/types';
import { useEffect } from 'react';
import { useChatsState } from '../../../../shared/state/chats/useChatsState';
import { IParticipant } from '../../../../shared/api/ChatsAPI/ChatsTypes';

type OfferRequestChatWrapperProps = {
  dealInfo: IRequest;
};

export const OfferRequestChatWrapper = ({
  dealInfo,
}: OfferRequestChatWrapperProps) => {
  const openedEntity = useChatsState((state) => state.openedEntity);
  const setOpenedEntity = useChatsState((state) => state.setOpenedEntity);

  const currentChatInfo = useChatsState((state) => state.currentChatInfo);
  const getChatInfo = useChatsState((state) => state.getChatInfo);

  const participantClient = currentChatInfo?.participants?.find(
    (user: IParticipant) => {
      return user.type === 'user';
    }
  );

  useEffect(() => {
    setOpenedEntity('offer_deal', dealInfo.id?.toString());
    return () => {
      setOpenedEntity(null, null);
    };
  }, [dealInfo.id]);

  useEffect(() => {
    getChatInfo(dealInfo.id?.toString(), dealInfo.author_uid);
  }, [dealInfo.id]);

  return (
    <ChatContainerStyled>
      {openedEntity.id && (
        <>
          {participantClient && (
            <ChatHeader
              isDealOnArgument={dealInfo?.status === 'on_argument'}
              clientNickName={participantClient?.caption ?? ''}
              clientRegisteredDate={participantClient?.created_at ?? ''}
            />
          )}
          <ChatBody
            dealID={openedEntity.id?.toString()}
            clientUid={dealInfo.author_uid}
          />
          <ChatFooter dealID={openedEntity.id?.toString()} />
        </>
      )}
    </ChatContainerStyled>
  );
};
