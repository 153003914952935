import { notification } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import { ArgsProps } from 'antd/es/notification/interface'

import styles from './notifications.module.css'

export const showInfoNotification = (params: ArgsProps) => {
  const {className, ...rest} = params

  notification.info({
    className: [className, styles.notificationDefault].filter(Boolean).join(' '),
    icon: <InfoCircleFilled style={{ color: 'rgba(22, 119, 255, 1)' }} />,
    ...rest,
  })
}

export const showErrorNotification = (params: ArgsProps) => {
  const {className, ...rest} = params

  notification.error({
    className: [className, styles.notificationError].filter(Boolean).join(' '),
    duration: 5,
    ...rest,
  })
}