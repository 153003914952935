import styled from 'styled-components';
import { Input } from '@pankod/refine-antd';

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTitleStyled = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 32px;
`;

export const CommentBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  .ant-input {
    min-height: 104px;
    max-height: 280px;
  }
  .ant-input-data-count {
    margin-top: 5px;
  }
`;

export const CommentTitleStyled = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 8px;
  span {
    color: #ff7875;
  }
`;

export const TextareaStyled = styled(Input.TextArea)`
  .ant-input:focus {
    border-color: black;
    box-shadow: none;
  }

  .ant-input::placeholder {
    color: #00000040;
  }

  .ant-input {
    color: black;
    border-color: #00000040;
    background: transparent;

    resize: none;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00000026;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
`;

export const HelperTextStyled = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 8px;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
