import Icon, {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { useMemo } from 'react';
import { DEAL_STATUS } from 'shared/constants';
import {
  GarantStatus,
  GoToExchangeStatus,
  OnPayment,
  ProcessingIcon,
} from 'shared/components/icons';
import { DealStatusProps } from 'shared/components/ui/DealStatus/DealsStatus';
import styled from 'styled-components';
import { TDealsStatus } from 'shared/types';

const STATUS_ICONS: Record<TDealsStatus, JSX.Element> = {
  [DEAL_STATUS.pending]: (
    <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.accepted]: (
    <CheckCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.warrantyCollateral]: (
    <span>
      <GarantStatus />
    </span>
  ),
  [DEAL_STATUS.waitingForFiatPayment]: (
    <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.waitingForCryptoDelivery]: (
    <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.waitingForFiatDelivery]: (
    <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.waitingForFiatReceiving]: (
    <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.processing]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.processingHold]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.processingHoldFiatPayment]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.processingCancellation]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.processingExpiration]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.processingCompletion]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.processingFiatDelivery]: (
    <span>
      <ProcessingIcon />
    </span>
  ),
  [DEAL_STATUS.ready]: (
    <span style={{ color: '#FFC069', fontSize: '16px' }}>
      <GoToExchangeStatus />
    </span>
  ),
  [DEAL_STATUS.completed]: (
    <CheckCircleFilled style={{ color: '#36CFC9', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.expired]: (
    <ClockCircleFilled style={{ color: '#FF7875', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.onArgument]: (
    <ExclamationCircleFilled style={{ color: '#FF7875', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.complaint]: (
    <ExclamationCircleFilled style={{ color: '#FF7875', fontSize: '16px' }} />
  ),
  [DEAL_STATUS.cancelled]: (
    <CloseCircleFilled style={{ color: '#FF7875', fontSize: '16px' }} />
  ),
};

const useDealStatusIcon = ({
  currentStatus,
  noGuarantee,
  isBuying,
}: DealStatusProps) => {
  return useMemo(() => {
    if (noGuarantee) {
      if (currentStatus === DEAL_STATUS.waitingForCryptoDelivery) {
        return isBuying ? (
          <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
        ) : (
          <Icon
            component={OnPayment}
            style={{ color: '#FFC069', fontSize: '16px' }}
          />
        );
      } else if (currentStatus === DEAL_STATUS.waitingForFiatDelivery) {
        return isBuying ? (
          <IconWrapper>
            <OnPayment />
          </IconWrapper>
        ) : (
          <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
        );
      } else if (currentStatus === DEAL_STATUS.waitingForFiatReceiving) {
        return (
          <CheckCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
        );
      }
    }

    return (
      STATUS_ICONS[currentStatus as TDealsStatus] ?? (
        <ClockCircleFilled style={{ color: '#69B1FF', fontSize: '16px' }} />
      )
    );
  }, [currentStatus, noGuarantee, isBuying]);
};

export default useDealStatusIcon;

const IconWrapper = styled.div`
  display: flex;
  align-items: 'center';
`;
