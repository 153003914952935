import { axios } from '../exios';
import { API_URL } from 'packages/keycloak-client/constants';
import { AxiosResponse } from 'axios';

type PendingCountResponse = {
  guarantee_count: number;
  non_guarantee_count: number;
};

export const getPendingDealsCount = async (): Promise<PendingCountResponse> => {
  try {
    const { data: pendingCountData } = await axios.get<
      AxiosResponse<PendingCountResponse[]>
    >(`${API_URL}/exchange-point-offer-requests/pending-count`);

    if (pendingCountData) {
      return pendingCountData.data[0];
    }
  } catch (err) {
    console.error(err);
  }
  return {
    guarantee_count: 0,
    non_guarantee_count: 0,
  };
};
