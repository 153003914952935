import React, { useEffect } from 'react';
import { CenteredStatusCaseStyle } from './DealsTable.styles';
import { TDeals } from 'shared/types';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import HeaderCellDescription from '../../../shared/components/ui/table/cells/HeaderCellDescription';
import CellDescription from '../../../shared/components/ui/table/cells/CellDescription';
import { IExchangePoint } from '../../../interfaces';
import { TableStyled } from '../../../styles';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { Typography } from '@pankod/refine-antd';
import { ModalKeys } from '../../../shared/components/ui/RenderModal/model/enums';
import { RenderModal } from '../../../shared/components/ui/RenderModal';
import { DealActionsDropdown } from './DealActionsDropdown';
import { useUserState } from '../../../shared/state/useUserState';
import { useExternalAccountState } from '../../../shared/state/useExternalAccountState';
import { DealsStatus } from 'shared/components/ui/DealStatus/DealsStatus';

interface DealsTableProps {
  deals: TDeals[];
  points: IExchangePoint[];
  refetchDeals: () => Promise<void>;
  pagination?: {
    current: number;
    total: number;
    pageSize: number;
  };
  setPagination?: (pagination: number) => void;
  isFullTable?: boolean;
  accountId?: number | null;
}

const DealsTable = (props: DealsTableProps) => {
  const {
    deals,
    points,
    setPagination,
    pagination,
    refetchDeals,
    isFullTable = true,
  } = props;

  const { t } = useTranslation(['offers', 'shared']);
  const navigate = useNavigate();

  const companyId = useUserState.getState()?.userApi?.company_id;

  const setExternalAccount = useExternalAccountState(
    (state) => state.setExternalAccount
  );

  const handleChange = (pageInfo: { current?: number; pageSize?: number }) => {
    setPagination?.(pageInfo?.current ?? 1);
  };

  useEffect(() => {
    (async function () {
      companyId && setExternalAccount(companyId);
    })();
  }, [companyId]);

  const offersColumns: ColumnsType<TDeals> = [
    {
      title: ' ',
      dataIndex: 'status',
      key: 'status',
      width: '1%',
      render: (_, record) => (
        <CenteredStatusCaseStyle>
          <DealsStatus
            currentStatus={record.status}
            noGuarantee={!record.offer?.is_guarantee}
            isCash={record.offer?.type === 'cash'}
            isBuying={record.offer?.is_buying_crypto}
          />
        </CenteredStatusCaseStyle>
      ),
    },
    {
      title: () => (
        <HeaderCellDescription
          title={
            isFullTable
              ? t('shared:тексты.Оффер')
              : `ID ${t('shared:тексты.Сделки')}`
          }
          description={isFullTable ? `ID ${t('shared:тексты.Сделки')}` : ''}
        />
      ),
      dataIndex: 'a',
      key: 'a',
      width: 90,
      render: (_, record) => {
        const currency =
          (record?.offer?.is_buying_crypto
            ? t('shared:тексты.Покупка')
            : t('shared:тексты.Продажа')) +
          ' ' +
          record?.offer?.crypto_currency_code;
        const dealId = record.name;

        return (
          <CellDescription
            titleLink={
              isFullTable
                ? `/${
                    record.offer?.is_guarantee
                      ? 'offers'
                      : 'no-guarantee-offers'
                  }/${record?.offer?.id}`
                : `/${
                    record.offer?.is_guarantee
                      ? 'transactions'
                      : 'no-guarantee-transactions'
                  }/request/${record?.id}`
            }
            description={isFullTable ? dealId : undefined}
            title={isFullTable ? currency : dealId}
            hideLink
          />
        );
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Объем актива')}
        />
      ),
      dataIndex: 'b',
      key: 'b',
      width: 48,
      render: (_, record) => {
        const currency =
          record.crypto_exchange_point_amount +
          ' ' +
          record?.offer?.crypto_currency_code?.replace('TRC20', '');
        return <CellDescription title={currency} />;
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Курс клиента')}
        />
      ),
      dataIndex: 'c',
      key: 'c',
      width: 48,
      render: (_, record) => {
        const currency =
          record.client_rate?.replace('.', ',') +
          ' ' +
          record?.offer?.cash_currency_code?.replace('CASH', '');
        return <CellDescription title={currency} />;
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Объем фиата')}
        />
      ),
      dataIndex: 'c',
      key: 'c',
      width: 48,
      render: (_, record) => {
        const currency =
          record.cash_exchange_point_amount +
          ' ' +
          record?.offer?.cash_currency_code?.replace('CASH', '');
        return <CellDescription title={currency} />;
      },
      onCell: () => ({
        onClick: (event: React.SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
        },
      }),
    },
    {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Способ оплаты')}
        />
      ),
      dataIndex: 'c1',
      key: 'c1',
      width: 48,
      render: (_, record) => {
        const requisiteName =
          record?.offer?.type === 'cash'
            ? t('shared:тексты.Наличные')
            : record?.requisite?.currency?.name;

        return (
          <CellDescription
            title={
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    color: '#E5F9FF',
                    overlayInnerStyle: {
                      color: '#000000A6',
                    },
                  },
                }}
              >
                {requisiteName}
              </Typography.Paragraph>
            }
          />
        );
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Клиент')}
        />
      ),
      dataIndex: 'c1',
      key: 'c1',
      width: 48,
      render: (_, record) => {
        return <CellDescription title={record.nickname} />;
      },
      // onCell: () => ({
      //   onClick: (event: React.SyntheticEvent) => {
      //     event.preventDefault();
      //     event.stopPropagation();
      //   },
      // }), // TODO: add link on client
    },
    {
      title: '',
      dataIndex: 'd',
      key: 'd',
      width: '5%',
      render: (_, record) => {
        return <DealActionsDropdown deal={record} fetchDeals={refetchDeals} />;
      },
      onCell: () => ({
        onClick: (event: React.SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
        },
      }),
    },
  ];

  if (isFullTable) {
    offersColumns.splice(-1, 0, {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Пункт обмена')}
        />
      ),
      dataIndex: 'c2',
      key: 'c2',
      width: 48,
      render: (_, record) => {
        const pointTitle =
          points?.find(
            (point) => point.id === record?.offer?.exchange_point?.id
          )?.name ?? t('shared:тексты.Имя пункта обмена недоступно');
        const city = record?.offer?.city;
        return (
          <CellDescription
            titleLink={
              points?.find(
                (point) => point.id === record?.offer?.exchange_point?.id
              )
                ? '/points/' + record?.offer?.exchange_point?.id
                : undefined
            }
            title={pointTitle}
          />
        );
      },
      onCell: () => ({
        onClick: (event: React.SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
        },
      }),
    });
  }

  const handleRow = (val: TDeals, isGuarantee: boolean | undefined = true) => {
    if (isFullTable) {
      navigate(
        `/${
          isGuarantee ? 'transactions' : 'no-guarantee-transactions'
        }/request/${val?.id}`
      );
    }
  };

  return (
    <>
      <div>
        <TableStyled
          onRow={(record: TDeals) => {
            return {
              onClick: () =>
                handleRow(record as TDeals, record?.offer?.is_guarantee),
            };
          }}
          pagination={
            pagination?.total && pagination?.total > 10
              ? {
                  current: pagination?.current,
                  total: pagination?.total,
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                }
              : false
          }
          columns={offersColumns as ColumnsType<object>}
          dataSource={deals}
          onChange={handleChange}
        ></TableStyled>
      </div>
      <RenderModal currentModalKey={ModalKeys.ModalPay} />
      <RenderModal currentModalKey={ModalKeys.ModalConfirmReceiptOfCash} />
      <RenderModal currentModalKey={ModalKeys.ModalOpenDispute} />
      <RenderModal currentModalKey={ModalKeys.ModalComplaint} />
      <RenderModal currentModalKey={ModalKeys.ModalCancelTransaction} />
      <RenderModal currentModalKey={ModalKeys.ModalConfirmPaymentSent} />
    </>
  );
};

export default DealsTable;
