import { Button, Popover, Typography } from '@pankod/refine-antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useContext, useMemo } from 'react';
import {
  ECreateExchangeOfferErrors,
  ECreateExchangeOfferOneError,
} from '../../../widgets/CreateOfferForm';
import { useTranslation } from 'react-i18next';
import { ExchangePointsContext } from '../../../shared/contexts/exchange-points-context';
import { useNavigate } from 'react-router';

const CreateOfferBtn = () => {
  const { t } = useTranslation(['shared']);
  const { activePoint } = useContext(ExchangePointsContext);
  const navigate = useNavigate();

  const createOfferErrors = useMemo(() => {
    const errors: (keyof typeof ECreateExchangeOfferErrors)[] = [];
    if (!activePoint) {
      errors.push("You can't operate this exchange point offers");
    }
    if (activePoint?.published_status === 'not_published') {
      errors.push('Exchange Point must be published');
    }
    if (activePoint?.is_scam) {
      errors.push('Exchange Point must not be in scam list');
    }
    if (activePoint?.monitoring_status === 'not_working') {
      errors.push('Exchange Point must be in status works');
    }
    if (activePoint?.monitoring_status === 'disabled') {
      errors.push('Exchange Point must not be disabled in monitoring');
    }
    if (activePoint?.status === 'not_working') {
      errors.push("Exchange Point must not be on admin's check");
    }
    return errors;
  }, [activePoint]);

  const getContentBtnPopover = () => {
    switch (createOfferErrors.length) {
      case 0: {
        return;
      }
      case 1: {
        return (
          <Typography.Text>
            {t(
              `shared:ошибки.${
                ECreateExchangeOfferOneError[createOfferErrors[0]]
              }`
            )}
          </Typography.Text>
        );
      }
      default: {
        return (
          <>
            <Typography.Text>
              {t(
                'shared:тексты.Вы не можете создать оффер от выбранного пункта обмена'
              )}
            </Typography.Text>
            <ul>
              {createOfferErrors.map((error) => (
                <li key={error}>
                  <Typography.Text>
                    {t(`shared:ошибки.${ECreateExchangeOfferErrors[error]}`)}
                  </Typography.Text>
                </li>
              ))}
            </ul>
          </>
        );
      }
    }
  };
  const handleCreate = () => {
    navigate(`create-offer`);
  };

  return (
    <Popover
      zIndex={createOfferErrors.length ? 100 : -1}
      color={'rgba(255, 241, 240, 1)'}
      placement={'left'}
      content={getContentBtnPopover()}
    >
      <Button
        onClick={handleCreate}
        disabled={!!createOfferErrors.length}
        icon={<PlusOutlined />}
        type={'primary'}
      >
        {t('shared:тексты.Создать оффер')}
      </Button>
    </Popover>
  );
};

export default CreateOfferBtn;
