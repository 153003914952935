import { ModalKeys } from 'shared/components/ui/RenderModal/model/enums';
import { TDeals } from 'shared/types';
import { useModalState } from 'shared/components/ui/RenderModal/model/useModalState';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { useCallback } from 'react';
import { DEAL_ACTION } from './useDealActions';
import { useExternalAccountState } from '../../../shared/state/useExternalAccountState';

export const useActionsHandler = (
  deal: TDeals,
  fetchDeals: () => Promise<void>
) => {
  const navigate = useNavigate();
  const openModal = useModalState((state) => state.openModal);

  const externalAccountId = useExternalAccountState(
    (state) => state.externalAccountId
  );

  const actionHandler = useCallback(
    (actionType: DEAL_ACTION) => {
      const modalProps = {
        id: deal.id,
        isBuyingCrypto: deal.offer?.is_buying_crypto,
        isGuarantee: deal.offer?.is_guarantee,
        name: deal.name,
        refetchData: fetchDeals,
      };

      if (actionType === DEAL_ACTION.ACCEPT_DEAL) {
        navigate(
          `/${
            modalProps.isGuarantee
              ? 'transactions'
              : 'no-guarantee-transactions'
          }/confirm-request/${deal.id}`
        );
      } else if (actionType === DEAL_ACTION.TOP_UP) {
        navigate(`/accounts/${externalAccountId}`);
      } else if (actionType === DEAL_ACTION.CANCEL_DEAL) {
        openModal(ModalKeys.ModalCancelTransaction, {
          ...modalProps,
          status: deal.status,
        });
      } else if (actionType === DEAL_ACTION.CONFIRM_PAYMENT_RECEIVED) {
        openModal(ModalKeys.ModalConfirmReceiptOfCash, modalProps);
      } else if (actionType === DEAL_ACTION.CONFIRM_PAYMENT_SENT) {
        openModal(ModalKeys.ModalConfirmPaymentSent, {
          ...modalProps,
          deal,
        });
      } else if (actionType === DEAL_ACTION.PAY) {
        openModal(ModalKeys.ModalPay, {
          ...modalProps,
          deal,
        });
      } else if (actionType === DEAL_ACTION.OPEN_DISPUTE) {
        openModal(ModalKeys.ModalOpenDispute, {
          ...modalProps,
          currency: 'USDT',
        });
      } else if (actionType === DEAL_ACTION.COMPLAINT) {
        openModal(ModalKeys.ModalComplaint, modalProps);
      }
    },
    [deal, fetchDeals, openModal]
  );

  return actionHandler;
};
