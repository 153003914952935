import {
  ChatBodyStyled,
  EmptyMessagesStyled,
  MessagesWrapper,
  SpinContainerStyled,
} from './Chat.styles';
import React, { useEffect, useRef, useState } from 'react';
import { useChatsState } from '../../../../shared/state/chats/useChatsState';
import { MessageItem } from './MessageItem';
import { IMessage } from '../../../../shared/api/ChatsAPI/ChatsTypes';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCentrifugeState } from '../../../../shared/state/centrifuge/useCentrifugeState';
import { ServerPublicationContext } from 'centrifuge';

type ChatBodyProps = {
  dealID: string;
  clientUid: string;
};

export const ChatBody = ({ dealID, clientUid }: ChatBodyProps) => {
  const { t } = useTranslation();

  const lastMessageRef = useRef<any>(null);
  const timeoutRef = useRef(null);

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const currentMessages = useChatsState((state) => state.currentMessages);
  const getMessages = useChatsState((state) => state.getMessages);

  const addNewMessageFromWS = useChatsState(
    (state) => state.addNewMessageFromWS
  );

  const isLoading = useChatsState((state) => state.isLoadingMessages);

  const [messages, setMessages] = useState<IMessage[]>(currentMessages);

  const addMessageFromWSCallBack = (message: IMessage) => {
    if (messages[messages?.length - 1]?.id !== message.id) {
      addNewMessageFromWS(message);
    }
  };

  useEffect(() => {
    getMessages(dealID);
    return () => {
      setMessages([]);
    };
  }, [dealID]);

  useEffect(() => {
    let messageWithAttachment: IMessage | null;

    const publicationHandler = (ctx: ServerPublicationContext) => {
      if (ctx?.channel?.includes('chat-user')) {
        return;
      } else if (
        ctx?.data?.message?.has_attachment === false &&
        ctx?.data?.message?.chat?.client_uid !== clientUid
      ) {
        return;
      } else if (
        ctx?.data?.type === 'new_message' &&
        ctx?.data?.message?.entity_id.toString() === dealID &&
        ctx?.data?.message?.chat?.entity_type === 'offer_deal' &&
        ctx?.data?.message?.is_system !== true
      ) {
        if (ctx?.data?.message?.has_attachment === false) {
          addMessageFromWSCallBack(ctx?.data?.message);
        } else {
          messageWithAttachment = ctx?.data?.message;
        }
      }
      if (
        ctx?.data?.type === 'attachment_uploaded' &&
        messageWithAttachment &&
        messageWithAttachment.id.toString() ===
          ctx.data.attachment.message_id.toString()
      ) {
        messageWithAttachment = {
          ...messageWithAttachment,
          attachment: ctx?.data?.attachment,
        };
        addNewMessageFromWS(messageWithAttachment);
        messageWithAttachment = null;
      }
    };
    centrifugeInstance?.on('publication', publicationHandler);
    return () => {
      centrifugeInstance?.removeListener('publication', publicationHandler);
    };
  }, [centrifugeInstance, dealID]);

  useEffect(() => {
    setMessages(currentMessages);
  }, [currentMessages, currentMessages?.length, dealID]);

  useEffect(() => {
    if (currentMessages?.length === 0 || messages?.length === 0 || isLoading)
      return;

    const lastMessage = lastMessageRef.current;
    if (lastMessage && lastMessage.parentElement) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // @ts-ignore
      timeoutRef.current = setTimeout(() => {
        requestAnimationFrame(() => {
          lastMessage.parentElement.scrollTo({
            top: lastMessage.offsetTop + lastMessage.offsetHeight,
            behavior: 'smooth',
          });
        });
      }, 700);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [messages, currentMessages, isLoading]);

  return (
    <ChatBodyStyled>
      {isLoading && (
        <SpinContainerStyled>
          <Spin />
        </SpinContainerStyled>
      )}
      {!isLoading && !!messages?.length && (
        <>
          <MessagesWrapper>
            {messages?.map((message: IMessage) => {
              return <MessageItem key={message.id} message={message} />;
            })}
          </MessagesWrapper>
          <div ref={lastMessageRef} />
        </>
      )}
      {!isLoading && !messages?.length && (
        <EmptyMessagesStyled>
          {t('shared:чат.Нет сообщений')}
        </EmptyMessagesStyled>
      )}
    </ChatBodyStyled>
  );
};
