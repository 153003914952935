import React, { useState } from 'react';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import { axios } from '../../../../../exios';
import { ModalKeys } from '../../model/enums';
import { Modal, Input } from 'antd';
import { useModalState } from '../../model/useModalState';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { Button, notification } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  HelperTextStyled,
  ModalBodyStyled,
  ModalTitleStyled,
} from './ModalOpenDispute.styles';

export const ModalOpenDispute = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const { TextArea } = Input;

  const [value, setValue] = useState('');

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalOpenDispute)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalOpenDispute).options
  );

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalOpenDispute);
    navigate(
      `/${
        modalOptions?.isGuarantee ? 'transactions' : 'no-guarantee-transactions'
      }/request/${modalOptions.id}`
    );
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios.put(
        `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/argue`,
        {
          reason: value,
        }
      );
      modalOptions?.refetchData();
      notification.info({
        message: t('Спор открыт'),
        icon: <InfoCircleFilled style={{ color: 'rgba(22, 119, 255, 1)' }} />,
        description: (
          <div>
            {t('Спор по сделке')}
            <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
              {modalOptions.name}
            </ButtonAsLinkStyled>
            {t(
              `на ${
                modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
              } успешно открыт`,
              {
                currency: modalOptions.currency,
              }
            )}
          </div>
        ),
        style: {
          borderLeft: '6px solid rgba(22, 119, 255, 1)',
          width: '400px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalOpenDispute);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalOpenDispute);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={636}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled>{t('Открыть спор вопрос')}</ModalTitleStyled>
        <HelperTextStyled>
          {t(
            'После открытия спора статус сделки изменится запятая и Вы не сможете восстановить её точка'
          )}
          <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
            {modalOptions.name}
          </ButtonAsLinkStyled>
          {t(`${modalOptions.isBuyingCrypto ? 'на покупку' : 'на продажу'}`, {
            currency: modalOptions.currency,
          })}
        </HelperTextStyled>
        <CommentBlockStyled>
          <CommentTitleStyled>{t('Укажите причину спора')}</CommentTitleStyled>
          <TextArea
            showCount
            maxLength={5000}
            onChange={onChangeHandler}
            placeholder={String(t('shared:тексты.Введите комментарий'))}
            value={value}
          />
        </CommentBlockStyled>
        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>{t('shared:кнопки.Назад')}</Button>
          <Button onClick={handleChangeOfferRequestStatus} type="primary">
            {t('Открыть спор')}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};
