import { useEffect, useState } from 'react';

import DealsTable from 'features/DealsTable/ui/DealsTable';
import { AdaptiveFullScreenLoader } from 'shared/components/full-screen-loader';
import { getDeals } from 'shared/api/getDeals';
import { LoaderWrapper, TableWrapper } from '../PaymentMethodPage.styles';
import { useTransactionsState } from '../../model/useTransactionsState';
import { TActiveFilter } from 'pages/offers/model/filters.types';

export const DealsTab = ({ requisiteName }: { requisiteName: string }) => {
  const { dealsMeta, setDealsMeta, deals, points, setDeals } =
    useTransactionsState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchDeals = async () => {
    const filters: TActiveFilter[] = [
      {
        field: 'requisite.currency.name',
        operator: '=',
        value: requisiteName,
      },
    ];

    const dealsData = await getDeals(filters, dealsMeta.current_page);

    setDeals(dealsData);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await fetchDeals();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dealsMeta.current_page]);

  return (
    <TableWrapper>
      {isLoading ? (
        <LoaderWrapper>
          <AdaptiveFullScreenLoader />
        </LoaderWrapper>
      ) : (
        <DealsTable
          refetchDeals={fetchDeals}
          pagination={{
            current: dealsMeta.current_page,
            total: dealsMeta.total,
            pageSize: dealsMeta.per_page,
          }}
          setPagination={setDealsMeta}
          deals={deals}
          points={points}
        />
      )}
    </TableWrapper>
  );
};
