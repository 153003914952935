import { DefaultTFuncReturn } from 'i18next';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox } from '@pankod/refine-antd';

import {
  TFilterSimple,
  TFilterSimpleWithId,
} from 'pages/offers/model/filters.types';
import { OfferFilter } from './OfferFilter';
import BlueTag from 'shared/components/ui/tags/BlueTag';

import {
  ButtonsContainerStyled,
  CheckboxesContainerStyled,
  ContainerHiddenStyled,
  ContainerStyled,
  ResetContainerStyled,
} from './OfferFilters.styles';

interface OfferFiltersProps {
  filters: {
    options: TFilterSimpleWithId[] | TFilterSimple[];
    type: string;
    nameDefaultProp?: DefaultTFuncReturn;
    label?: DefaultTFuncReturn;
    isMultiple?: boolean;
    isDisabled?: boolean;
    search?: boolean;
  }[];
  resetAll?: () => void;
  setFilter: (values: number[] | string, type: string) => void;
  setFilterCollapsed: (value: number | string, type: string) => void;
  checkboxes?: {
    name: DefaultTFuncReturn;
    value: boolean;
    onChange: (value: boolean) => void;
  }[];
  isFiltersCollapsed: boolean;
}

export const OfferFilters = memo((props: OfferFiltersProps) => {
  const {
    filters,
    setFilter,
    setFilterCollapsed,
    isFiltersCollapsed,
    resetAll,
    checkboxes,
  } = props;
  const { t } = useTranslation(['shared']);
  const isDisabled =
    !checkboxes?.some((check) => check.value) &&
    !filters
      .filter((filter) => filter.type !== 'ccyCurrFilters')
      .some((filter) => filter.options.some((opt) => opt.checked));
  if (isFiltersCollapsed) {
    return (
      <ContainerHiddenStyled>
        {filters
          .filter((filter) => filter.type !== 'ccyCurrFilters')
          .map((filter) =>
            filter.options
              .filter((opt) => opt.checked)
              .map((opt) => {
                if (filter.type === 'pointsFilters') {
                  return (
                    <BlueTag
                      key={'tag:' + opt.name}
                      closable
                      onClose={() =>
                        setFilterCollapsed(
                          (opt as TFilterSimpleWithId).id,
                          filter.type
                        )
                      }
                    >
                      {opt.t ? t(opt.t + opt.label) : opt.name}
                    </BlueTag>
                  );
                } else {
                  return (
                    <BlueTag
                      key={'tag:' + opt.name}
                      closable
                      onClose={() => setFilterCollapsed(opt.name, filter.type)}
                    >
                      {opt.t ? t(opt.t + opt.label) : opt.name}
                    </BlueTag>
                  );
                }
              })
          )}
      </ContainerHiddenStyled>
    );
  }
  return (
    <ContainerStyled>
      {filters.map((filter, index) => (
        <OfferFilter
          key={index}
          type={filter.type}
          options={filter.options}
          setFilter={setFilter}
          isDisabled={filter.isDisabled}
          search={filter.search}
          isMultiple={filter.isMultiple}
          nameDefaultProp={filter.nameDefaultProp}
          label={filter.label}
        />
      ))}
      <ButtonsContainerStyled>
        <CheckboxesContainerStyled>
          {checkboxes?.map((checkbox) => (
            <Checkbox
              checked={checkbox.value}
              onChange={(e) => checkbox.onChange(e.target.checked)}
            >
              {checkbox.name}
            </Checkbox>
          ))}
        </CheckboxesContainerStyled>
        {resetAll && (
          <ResetContainerStyled>
            <Button
              icon={<DeleteOutlined />}
              type={'text'}
              disabled={isDisabled}
              onClick={resetAll}
            >
              {t('shared:кнопки.Сбросить фильтры')}
            </Button>
          </ResetContainerStyled>
        )}
      </ButtonsContainerStyled>
    </ContainerStyled>
  );
});
