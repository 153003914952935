import { Popover } from '@pankod/refine-antd';
import { useDealStatusLabel } from 'shared/hooks/useDealStatusLabels';
import useDealStatusIcon from 'shared/hooks/useDealStatusIcon';
import { TDealsStatus } from 'shared/types';

export interface DealStatusProps {
  currentStatus: TDealsStatus;
  noGuarantee?: boolean;
  isCash?: boolean;
  isBuying?: boolean;
}

export const DealsStatus = ({
  currentStatus,
  noGuarantee,
  isCash,
  isBuying,
}: DealStatusProps) => {
  const statusLabel = useDealStatusLabel({
    currentStatus,
    noGuarantee,
    isCash,
    isBuying,
  });

  const icon = useDealStatusIcon({
    currentStatus,
    noGuarantee,
    isBuying,
  });

  return (
    <Popover
      zIndex={1000000}
      color="#E5F9FF"
      placement="top"
      content={statusLabel}
    >
      {icon}
    </Popover>
  );
};
