import { TCurrencies, TCurrency } from "shared/types";
import { MAP_API_URL } from "../../packages/keycloak-client/constants";
import { requestWithCache } from '../helpers/requestWithCache';

/**
 * Получить список всех фиатных и криптовалют в системе
 * @returns 
 */
export const getCurrencies = async (): Promise<TCurrencies[]> => {
  try {

    const data: { data: TCurrency[]  ; meta: { total: number } } = await requestWithCache(`${MAP_API_URL}/payment-system-currency/search`, {
      method: 'POST',
      data: {
        limit: 1000,
        page: 1,
      }
    });

    const fiat: TCurrency[] = []
    const digital: TCurrency[] = []
    data?.data.forEach(curr => {
      if (curr.currency_type === 'fiat' && curr.type === 'cash') {
        fiat.push(curr)
      }
      if (curr.currency_type === 'digital' && curr.type === 'crypto') {
        digital.push(curr)
      }
    })
    return [
      {
        arr: fiat,
        type: 'fiat'
      },
      {
        arr: digital,
        type: 'digital'
      }
    ]
  } catch {
    return [{
      arr: [],
      type: 'fiat'
    },
      {
        arr: [],
        type: 'digital'
      }]
  }
}
