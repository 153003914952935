import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { IResponseShell } from '../../../shared/interfaces';
import { IAccount } from '../../ExchangePoints/interface';

export const getExternalAccount = async (companyId: number) => {
  try {
    const { data } = await axios.post<IResponseShell<IAccount[]>>(
      `${API_URL}/accounts/search`,
      {
        filters: [
          {
            field: 'company_id',
            operator: '=',
            value: companyId,
          },
          {
            field: 'is_external',
            operator: '=',
            value: true,
          },
          // раскомментить, когда будет что-то кроме USDT
          // {
          //   field: 'currency.shortname',
          //   operator: '=',
          //   value: currencyShortname,
          // },
        ],
      }
    );
    return data.data[0];
  } catch (err) {
    console.error('get account error');
  }
};
