import styled from 'styled-components';
import { Button } from '@pankod/refine-antd';

export const ModalDescriptionStyled = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #000000e0;
  margin-bottom: 8px;
`;
export const ModalContentWrapperStyled = styled.div`
  background-color: #0000000a;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
`;
export const ModalTitleStyled = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 32px;
  color: black;
`;
export const ModalRowStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalAmountTitleStyled = styled.div`
  margin-bottom: 12px;
  color: #000000a6;
`;

export const ModalAmountStyled = styled.div`
  font-weight: 600;
  color: #000000e0;
  word-spacing: 1px;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const LinkContainerStyled = styled.div`
  width: auto;
  margin-left: auto;
  float: right;
`;

export const LinkStyled = styled(Button).attrs({
  type: 'link',
})`
  padding-inline: 0;
`;

export const ButtonAsLinkStyled = styled.button`
  color: #1677ff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  &:hover,
  &:focus,
  &:active {
    color: #1677ff;
    background-color: transparent;
    &svg {
      color: #1677ff;
    }
  }
`;
