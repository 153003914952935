import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { axios } from '../../../../../exios';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';

export const ModalCancelOffer = () => {
  const { t } = useTranslation(['offers', 'shared']);
  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalCancelOffer)
  );
  const closeModal = useModalState((state) => state.closeModal);
  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalCancelOffer).options
  );
  const closeNoAccountModal = () => {
    closeModal(ModalKeys.ModalCancelOffer);
  };
  const handleCancel = async () => {
    try {
      await axios.patch(`${API_URL}/exchange-point-offers/${modalOptions.id}`, {
        status: 'declined',
      });
      notification.info({
        message: t('Оффер отменён'),
        description: `${
          modalOptions.isBuyingCrypto
            ? t('Оффер на покупку USDT успешно отменён')
            : t('Оффер на продажу USDT успешно отменён')
        }`,
        style: { borderLeft: '6px solid #1677FF', width: '400px' },
      });
      modalOptions.callback();
    } catch {
      notification.error({
        message: t('Ошибка при отмене оффера'),
        description: t(
          'Произошла ошибка при выполнении запроса точка Пожалуйста запятая попробуйте повторить запрос позже точка'
        ),
        style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      });
    } finally {
      closeModal(ModalKeys.ModalCancelOffer);
    }
  };
  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalCancelOffer);
  };
  return (
    <Modal
      open={isModalOpen}
      onCancel={closeNoAccountModal}
      footer={null}
      width={460}
      closable={false}
      centered
    >
      <ModalBodyStyled>
        <ModalRowStyled>
          <ExclamationCircleOutlined
            size={21}
            style={{ color: '#1677FF', fontSize: 21 }}
          />
          <ModalTextStyled>
            <ModalTitleStyled>{t('Отмена оффера')}</ModalTitleStyled>
            <ModalDescriptionStyled>
              {t(
                "При отмене оффера все сделки, находящиеся в статусе 'На рассмотрении' будут отменены точка Также вы не сможете вернуть оффер в работу точка Вы уверены, что хотите отменить оффер?"
              )}
            </ModalDescriptionStyled>
          </ModalTextStyled>
        </ModalRowStyled>
        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>{t('shared:кнопки.Назад')}</Button>
          <Button onClick={handleCancel} type="primary">
            {t('shared:тексты.Отменить оффер')}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};

const ModalDescriptionStyled = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 8px;
`;
const ModalTextStyled = styled.div``;
const ModalTitleStyled = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;
const ModalRowStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`;

const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
