import {
  ButtonsContainerStyled,
  ContainerStyled,
  EmptyOffersStyled,
} from './OffersTable.styles';
import { TOffers } from '../../../pages/offers/model/types';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import HeaderCellDescription from '../../../shared/components/ui/table/cells/HeaderCellDescription';
import React, { Suspense } from 'react';
import CellDescription from '../../../shared/components/ui/table/cells/CellDescription';
import { LockIcon } from '../../../shared/components/icons';
import { IExchangePoint } from '../../../interfaces';
import { CountryFlag } from '../../../shared/components/CountryFlag';
import { OfferButtons, OfferStatus } from './OfferStatus';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { FileTextOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import { ErrorBoundary } from '../../../shared/components/ErrorBoundary';
import { TableStyled } from '../../../styles';
import { useResize } from '../../../shared/hooks/useResize';
import { removeTrailingZeros } from '../../../shared/helpers/removeTrailingZeros';

const DealsTableAccotgion = React.lazy(() => import('./ui/DealsAccordion'));

interface OffersTableProps {
  offers: TOffers[];
  points: IExchangePoint[];
  pagination?: {
    current: number;
    total: number;
    pageSize: number;
  };
  setPagination?: (pagination: number) => void;
  callbacks: {
    onCancel: (
      id: number,
      isReversed: boolean,
      cryptoCurrencyCode: string
    ) => void;
  };
  isOffersEmpty: boolean;
}

export const OffersTable = (props: OffersTableProps) => {
  const {
    offers,
    points,
    setPagination,
    pagination,
    callbacks,
    isOffersEmpty,
  } = props;
  const { t } = useTranslation(['operationsAndCashFlow', 'shared']);
  const navigate = useNavigate();

  // нужно
  // const [currentOffer, setCurrentOffer] = useState<Record<number, boolean>>({})
  const { isScreen350, isScreen1024, isScreen1099 } = useResize();

  // нужно
  // const offers = [...offersData]
  // useEffect(() => {
  //   const offersIds = offers.reduce((acc, item) => {
  //     acc[item.id] = false;
  //     return acc;
  //   }, {} as Record<number, boolean>);
  //   setCurrentOffer(offersIds)
  // }, [offers.length]);

  const handleChange = (pageInfo: { current?: number; pageSize?: number }) => {
    setPagination?.(pageInfo?.current ?? 1);
  };

  const offersColumns: ColumnsType<TOffers> = [
    {
      title: ' ',
      dataIndex: 'status',
      key: 'status',
      width: '1%',
      render: (_, record) => {
        const status = record.status;
        return (
          <OfferStatus
            status={status}
            colors={{
              blue: 'active',
              red: 'declined',
              green: 'done',
            }}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'typeOffer',
      key: 'typeOffer',
      width: 48,
      render: (_, record) => {
        const currency =
          (record.isBuyingCrypto
            ? t('shared:тексты.Покупка')
            : t('shared:тексты.Продажа')) +
          ' ' +
          record.cryptoCurrencyCode;
        const offerName = record.name;
        return (
          <CellDescription
            description={offerName}
            title={currency}
            iconRightUp={record.cryptoCommissionAmount !== '0' && <LockIcon />}
          />
        );
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={t('shared:тексты.Начальный объем актива')}
          title={t('shared:тексты.Осталось актива')}
        />
      ),
      dataIndex: 'volumeActive',
      key: 'volumeActive',
      width: 48,
      render: (_, record) => {
        const currency =
          removeTrailingZeros(record.currentCryptoAmount).replace('.', ',') +
          ' ' +
          record.cryptoCurrencyCode.replace('TRC20', '');
        const initialCryptoAmount =
          removeTrailingZeros(record.initialCryptoAmount).replace('.', ',') +
          ' ' +
          record.cryptoCurrencyCode.replace('TRC20', '');
        return (
          <CellDescription description={initialCryptoAmount} title={currency} />
        );
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={t('shared:тексты.Сумма сделки')}
          title={t('shared:тексты.Курс клиента')}
        />
      ),
      dataIndex: 'rateClient',
      key: 'rateClient',
      width: 48,
      render: (_, record) => {
        const currency =
          removeTrailingZeros(record.clientRate).replace('.', ',') +
          ' ' +
          record.cashCurrencyCode.replace('CASH', '');
        const minMaxAmount =
          removeTrailingZeros(record.cashMinAmount).replace('.', ',') +
          '-' +
          removeTrailingZeros(record.cashMaxAmount).replace('.', ',') +
          ' ' +
          record.cashCurrencyCode.replace('CASH', '');
        return <CellDescription description={minMaxAmount} title={currency} />;
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={''}
          title={t('shared:тексты.Способ оплаты')}
        />
      ),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: 48,
      render: (_, record) => {
        const cyrrency = record?.requisites?.map((el) => {
          return el.currency.name;
        });

        return (
          <CellDescription
            title={
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  tooltip: {
                    color: '#E5F9FF',
                    overlayInnerStyle: {
                      color: '#000000A6',
                    },
                  },
                }}
              >
                {record.type === 'cash' ? t('Наличные') : cyrrency?.join(', ')}
              </Typography.Paragraph>
            }
          />
        );
      },
    },
    {
      title: () => (
        <HeaderCellDescription
          description={t('shared:тексты.Место обмена')}
          title={t('shared:тексты.Пункт обмена')}
        />
      ),
      dataIndex: 'exchangePoint',
      key: 'exchangePoint',
      width: 48,
      render: (_, record) => {
        const pointTitle =
          points.find((point) => point.id === record.exchangePointId)?.name ??
          t('shared:тексты.Имя пункта обмена недоступно');
        const city = record.city;
        return (
          <CellDescription
            titleLink={'/points/' + record.exchangePointId}
            description={city}
            title={pointTitle}
            iconLeftDown={
              <CountryFlag
                width={20}
                height={14}
                countryCode={record.countryCode}
              />
            }
          />
        );
      },
      onCell: () => ({
        onClick: (event: React.SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
        },
      }),
    },
    // {
    //   title: '',
    //   dataIndex: 'd',
    //   key: 'd',
    //   width: '5%',
    //   // onCell: () => ({
    //   //   onClick: (event: any) => {
    //   //     event.preventDefault();
    //   //     event.stopPropagation();
    //   //   },
    //   // }),
    //   render: (_, record) => {
    //
    //     return (
    //       <ButtonsContainerStyled>
    //         {<OfferButtons status={record.status} callbacks={{
    //           onCancel: () => callbacks.onCancel(record.id, record.isBuyingCrypto, record.cryptoCurrencyCode),
    //           onOpen: () => {},
    //         }}/>}
    //       </ButtonsContainerStyled>
    //     );
    //   },
    //   onCell: () => ({
    //     onClick: (event: React.SyntheticEvent) => {
    //       event.preventDefault();
    //       event.stopPropagation();
    //     },
    //   }),
    // },
    Table.EXPAND_COLUMN,
  ];

  const handleRow = (val: TOffers, isGuarantee: boolean) => {
    navigate(`/${isGuarantee ? 'offers' : 'no-guarantee-offers'}/` + val.id);
  };

  const openRowTable = (offer: any) => {
    return (
      <ErrorBoundary>
        <Suspense fallback={'Loading...'}>
          <DealsTableAccotgion offerId={String(offer.id)} points={points} />
        </Suspense>
      </ErrorBoundary>
    );
  };

  const renderIconAccordion = (
    record: TOffers,
    onExpand: any,
    expanded: boolean
  ) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <ButtonsContainerStyled>
          {
            <OfferButtons
              status={(record as TOffers)?.status}
              callbacks={{
                onCancel: () =>
                  callbacks.onCancel(
                    (record as TOffers)?.id,
                    (record as TOffers)?.isBuyingCrypto,
                    (record as TOffers)?.cryptoCurrencyCode
                  ),
                onOpen: () => {},
              }}
            />
          }
        </ButtonsContainerStyled>

        {/*<MmfToggleStyled*/}
        {/*  onClick={(event) => {*/}
        {/*    // нужно*/}
        {/*    // setCurrentOffer(prev => ({...prev, [(record as TOffers).id]: !prev[(record as TOffers).id]}))*/}
        {/*    // setCurrentOffer(*/}
        {/*    //   { ...currentOffer, [(record as TOffers).id]: !currentOffer[(record as TOffers).id] },*/}
        {/*    // );*/}
        {/*    onExpand(record, event);*/}
        {/*  }}*/}
        {/*  $isShow={expanded}*/}
        {/*>*/}
        {/*  <Popover*/}
        {/*    placement="top"*/}
        {/*    color={'#E5F9FF'}*/}
        {/*    style={{ display: 'flex' }}*/}
        {/*    content={*/}
        {/*      expanded*/}
        {/*        ? t('shared:тексты.Скрыть вложенные Сделки')*/}
        {/*        : t('shared:тексты.Показать вложенные Сделки')*/}
        {/*    }*/}
        {/*  >*/}
        {/*    <DoubleRightOutlined />*/}
        {/*  </Popover>*/}
        {/*</MmfToggleStyled>*/}
      </div>
    );
  };

  return (
    <>
      {isOffersEmpty ? (
        <EmptyOffersStyled className="empty-table">
          <FileTextOutlined />
          {t('shared:тексты.Нет созданных офферов')}
        </EmptyOffersStyled>
      ) : (
        <ContainerStyled>
          {offers?.length ? (
            <TableStyled
              $isScreen350={isScreen350}
              $isScreen1024={isScreen1024}
              $isScreen1099={isScreen1099}
              columns={offersColumns as ColumnsType<object>}
              dataSource={offers}
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) =>
                  renderIconAccordion(record as TOffers, onExpand, expanded),
                expandedRowRender: (offer) => openRowTable(offer),
              }}
              onChange={handleChange}
              pagination={
                pagination?.total && pagination?.total > 10
                  ? {
                      current: pagination?.current,
                      total: pagination?.total,
                    }
                  : false
              }
              onRow={(record: TOffers) => ({
                onClick: () => {
                  handleRow(record as TOffers, record.isGuarantee);
                },
              })}
            ></TableStyled>
          ) : (
            <EmptyOffersStyled className="empty-table">
              <FileTextOutlined />
              {t('shared:тексты.Нет результатов')}
            </EmptyOffersStyled>
          )}
        </ContainerStyled>
      )}
    </>
  );
};
