import { Form, FormItemProps, Select, SelectProps } from '@pankod/refine-antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import countryList from 'react-select-country-list';

interface ICountrySelectFromItem {
  formItemProps: FormItemProps;
  onSelect?: SelectProps<any, { label: string; value: string }>['onSelect'];
  onClear?: () => void;
  required?: boolean;
}

const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const CountrySelectFromItem = ({
  onSelect,
  onClear,
  formItemProps,
  required = true,
}: ICountrySelectFromItem) => {
  const { t } = useTranslation(['shared']);
  const options = useMemo(() => countryList().getData(), []);

  return (
    <Form.Item {...formItemProps} required={required}>
      <Select
        placeholder={String(t('shared:тексты.Выберите страну'))}
        optionFilterProp="children"
        filterOption={filterOption}
        options={options}
        onSelect={onSelect}
        onClear={onClear}
        allowClear
        showSearch
      />
    </Form.Item>
  );
};
// form.setFieldValue("country", null)
export default CountrySelectFromItem;
