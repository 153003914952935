import { IRequestHistory } from '../../../pages/offer-request/model/types';
import dayjs from 'dayjs';
import { Typography } from '@pankod/refine-antd';
import { IClient } from '../../../interfaces';
import { IStaff } from '../../../shared/interfaces';
import { Flex } from '../../../shared/components/styled';
import { Link } from '@pankod/refine-react-router-v6';
import TextExpandableBlock from '../../../shared/components/ui/text-expandable-block/TextExpandableBlock';
import { useTranslation } from 'react-i18next';
import { TableStyled } from './OfferRequestHistoryTable.style';
import { ColumnsType } from 'antd/es/table';
import { getStatusLabels } from 'shared/hooks/useDealStatusLabels';
import { TDealsStatus } from 'shared/types';

enum EInitiatorType {
  client = 'Клиент',
  exchange_point = 'Сотрудник пункта обмена',
  system = 'Система',
  referee = 'Арбитр',
}

const linkedTypes: IRequestHistory['initiator_type'][] = [
  'client',
  'exchange_point',
];

interface IProps {
  history: IRequestHistory[];
  client: IClient;
  dealStatus: TDealsStatus;
  noGuarantee?: boolean;
  isCash?: boolean;
  isBuying?: boolean;
}

const OfferRequestHistoryTable = ({
  isCash,
  client,
  history,
  isBuying,
  noGuarantee,
  dealStatus,
}: IProps) => {
  const { t } = useTranslation(['offers', 'shared']);

  const getName = (initiator: IRequestHistory['initiator']) => {
    return (initiator as IClient)?.name
      ? (initiator as IClient)?.name
      : (initiator as IStaff)?.username;
  };

  const getReasonHandler = (reason: string) => {
    switch (reason) {
      case 'not_deposited_at_the_set_time': {
        return t('Гарант не депонирован до назначенного времени обмена точка');
      }
      case 'not_paid_at_the_set_time': {
        return t(
          'Гарант не депонирован в установленное время (не менее чем за 2'
        );
      }
      case 'no_cash_received': {
        return t('Оплата не предоставлена в установленное время (не менее чем');
      }
      case 'offer_was_done': {
        return t('Родительский оффер завершен');
      }
      case 'offer_was_declined': {
        return t('Родительский оффер отклонен');
      }
      case 'The execution of the deal was determined by the referee in order to resolve the argument.': {
        return t('Выполнение сделки установил арбитр в порядке решения спора');
      }
      default: {
        return reason;
      }
    }
  };

  const columns: ColumnsType<IRequestHistory> = [
    {
      title: String(t('shared:таблицы.Дата и время')),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 300,
      render: (date: IRequestHistory['created_at']) =>
        dayjs(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: String(t('shared:тексты.Событие')),
      dataIndex: 'status',
      key: 'status',
      width: '200px',
      render: (status: IRequestHistory['status']) =>
        t(
          `shared:статусы.${getStatusLabels({
            currentStatus: status,
            noGuarantee: noGuarantee,
            isCash: isCash,
            isBuying: isBuying,
          })}`
        ),
    },
    {
      title: String(t('shared:тексты.Инициатор')),
      dataIndex: 'initiator',
      key: 'initiator',
      width: '25%',
      render: (
        initiator: IRequestHistory['initiator'],
        { initiator_type }: IRequestHistory
      ) => (
        <Flex align={'flex-start'} vertical>
          {linkedTypes.includes(initiator_type) && client && (
            <Link
              to={
                initiator_type === 'client'
                  ? `/clients/${client?.id}`
                  : `/staff/${initiator?.id}`
              }
            >
              {getName(initiator)}
            </Link>
          )}
          <Typography.Text type={'secondary'}>
            {t(`shared:тексты.${EInitiatorType[initiator_type]}`)}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: String(t('shared:тексты.Причина')),
      dataIndex: 'reason',
      key: 'reason',
      width: '300px',
      render: (reason: IRequestHistory['reason']) =>
        reason ? (
          <TextExpandableBlock text={getReasonHandler(reason)} />
        ) : (
          <span>-</span>
        ),
    },
  ];
  return (
    <TableStyled
      columns={columns as ColumnsType<object>}
      dataSource={history}
      pagination={false}
    />
  );
};

export default OfferRequestHistoryTable;
