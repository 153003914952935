import { getDeals } from 'shared/api/getDeals';
import { useDealsState } from '../model/useDealsState';

export const fetchDeals = async () => {
  const {
    activeFilters,
    dealsType,
    meta: { current_page: currentPage },
  } = useDealsState.getState();

  const data = await getDeals(activeFilters, currentPage, dealsType);

  useDealsState.getState().setDeals(data);
};
