import styled from 'styled-components';

export const EmptyTableMessage = ({
  text,
  subtext,
  icon,
}: {
  text: string;
  subtext: string;
  icon: React.ReactNode;
}) => {
  return (
    <EmptyOffersStyled>
      <EmptyWrapperStyled>
        {icon}
        {text}
      </EmptyWrapperStyled>
      <SmallSpanStyled>{subtext}</SmallSpanStyled>
    </EmptyOffersStyled>
  );
};

export const EmptyOffersStyled = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 16px;
  svg {
    height: 80px;
    width: 80px;
    padding: 10px;
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const EmptyWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  font-size: 16px;
`;

export const SmallSpanStyled = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  max-width: 400px;
`;
