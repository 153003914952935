import React, { ReactNode, Suspense, useEffect, useMemo } from 'react';
import { Layout as AntdLayout, MenuProps, ThemeConfig } from 'antd';
import type { RefineLayoutHeaderProps } from '@pankod/refine-ui-types';
import { UserDropdown } from './user-dropdown';
import styled, { css } from 'styled-components';
import { useResize } from '../../../hooks/useResize';
import fb from '../../../../packages/fb/fb';
import { FullScreenLoader } from '../../full-screen-loader';
import { ErrorBoundary } from '../../ErrorBoundary';
import NotificationSelect from './notification-select/NotificationSelect';
import { useSystemNotificationsBellState } from '../../../state/useSystemNotificationsBellState';
import { ACCOUNT_URL, MAP_URL } from 'packages/keycloak-client/constants';
import { useTranslation } from 'react-i18next';
import { LinksDropdown } from 'shared/ui-kit/LinksDropdown/LinksDropdown';
import { Badge, ConfigProvider } from '@pankod/refine-antd';
import { CommentOutlined } from '@ant-design/icons';
import { useChatsState } from '../../../state/chats/useChatsState';
import { useCentrifugeState } from '../../../state/centrifuge/useCentrifugeState';
import { ServerPublicationContext } from 'centrifuge';
import { useSidebarState } from '../../../state/useSidebarState';

const LanguageSelect = React.lazy(() => import('./language-select'));

const UserExchangePointsDropdown = React.lazy(
  () => import('./user-exchange-points/index')
);

const config: ThemeConfig = {
  token: {
    colorBorderBg: 'linear-gradient(45deg, #088473 0%, #12BFA8 100%)',
  },
};

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const { isScreen1300 } = useResize();
  const { t, i18n } = useTranslation();

  const centrifugeInstance = useCentrifugeState(
    (state) => state.centrifugeInstance
  );

  const { clientCount } = useChatsState((state) => state.unreadMessagesCounts);

  const { corporateCount } = useChatsState(
    (state) => state.unreadMessagesCounts
  );

  const setUnreadMessagesCount = useChatsState(
    (state) => state.setUnreadMessagesCount
  );

  const getInitialPendingDealsCount = useSidebarState(
    (state) => state.getInitialPendingDealsCount
  );

  const setPendingDealsCountFromWS = useSidebarState(
    (state) => state.setPendingDealsCountFromWS
  );

  const getNotificationSearch = useSystemNotificationsBellState(
    (state) => state.getNotificationSearch
  );
  const getNotificationsCount = useSystemNotificationsBellState(
    (state) => state.getNotificationsCount
  );

  useEffect(() => {
    setUnreadMessagesCount();
    getNotificationSearch();
    getNotificationsCount();
    getInitialPendingDealsCount();
  }, []);

  useEffect(() => {
    const publicationHandler = (ctx: ServerPublicationContext) => {
      if (ctx?.data?.type === 'new_message') {
        setUnreadMessagesCount();
      }
      if (ctx?.data?.data?.type === 'pending_deals_count') {
        setPendingDealsCountFromWS(
          ctx?.data?.data?.guaranteeCount,
          ctx?.data?.data?.nonGuaranteeCount
        );
      }
    };
    centrifugeInstance?.on('publication', publicationHandler);
    return () => {
      centrifugeInstance?.removeListener('publication', publicationHandler);
    };
  }, [centrifugeInstance]);

  const handleGoToMap = () => {
    window.open(MAP_URL);
  };

  const handleGoToAccount = () => {
    window.open(ACCOUNT_URL);
  };
  const userComponent = (): ReactNode => {
    if (!window.location.pathname.includes('payment-logout')) {
      return (
        <ErrorBoundary>
          <Suspense fallback={<FullScreenLoader />}>
            <UserDropdown />
          </Suspense>
        </ErrorBoundary>
      );
    }
  };
  const items: MenuProps['items'] = useMemo(() => {
    const elems = [
      {
        key: 'Platform',
        label: (
          <a role={'button'} onClick={handleGoToAccount}>
            <LabelContainerStyled>
              {/*<StarOutlined />*/} <span>Platform</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('shared:тексты.Кошелёк запятая сделки и настройки аккаунта')}
            </DescriptionStyled>
          </a>
        ),
        style: {},
      },
      {
        key: 'Exchange_Map',
        label: (
          <a role={'button'} onClick={handleGoToMap}>
            <LabelContainerStyled>
              {/*<StarOutlined />*/} <span>Exchange_Map</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t(
                'shared:тексты.Сделки с гарантом запятая мониторинг и партнерство'
              )}
            </DescriptionStyled>
          </a>
        ),
        style: {},
      },
      {
        key: 'Fin_MS',
        label: (
          <>
            <LabelContainerStyled>
              {/*<StarOutlined />*/} <span>Fin_MS</span>
            </LabelContainerStyled>
            <DescriptionStyled>
              {t('shared:тексты.Управление пунктами обмена')}
            </DescriptionStyled>
          </>
        ),
        style: {},
      },
    ];
    return elems;
  }, [i18n.language]);

  return (
    <HeaderWrapper
      $mode={!window.location.pathname.includes('payment-logout')}
      $isScreen1300={isScreen1300}
      onClick={() => {
        !isScreen1300 && fb.emit('closeSideBar', true);
      }}
      onTouchStart={() => {
        !isScreen1300 && fb.emit('closeSideBar', true);
      }}
    >
      <HeaderContent>
        <UserExchangePointsDropdown />
        <HeaderButtons>
          <WrapperIconStyled>
            <ConfigProvider theme={config}>
              <IconChatWrapperStyled
                onClick={() => {
                  window.location.href = ACCOUNT_URL + '/chat';
                }}
              >
                <BadgeStyled
                  offset={[4, 0]}
                  count={clientCount + corporateCount}
                  size={'default'}
                  color={'#08979C'}
                  showZero={true}
                >
                  <CommentOutlined />
                </BadgeStyled>
              </IconChatWrapperStyled>
            </ConfigProvider>
            <NotificationSelect />
          </WrapperIconStyled>
          <DropdownsContainerStyled>
            {userComponent()}
            <FlexStyled>
              <DividerStyled />
              <LinksDropdown
                theme={'finms'}
                selected={['Fin_MS']}
                items={items}
              />
            </FlexStyled>
          </DropdownsContainerStyled>
        </HeaderButtons>
      </HeaderContent>
    </HeaderWrapper>
  );
};
const LoginWrapper = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
`;

const IconChatWrapperStyled = styled.div`
  padding-right: 10px;
`;

const WrapperIconStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const BadgeStyled = styled(Badge)`
  cursor: pointer;
  padding-top: 4px;
  padding-right: 3px;
  padding-bottom: 6px;
  //height: 20px;
  //width: 20px;
  direction: ltr;
  color: #ffffffcc;
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 4px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const HeaderWrapper = styled(AntdLayout.Header)<{
  $mode: boolean;
  $isScreen1300: boolean;
}>`
  height: 72px;
  background-color: #14141f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 0;
  padding-inline: 16px;
  ${({ $isScreen1300 }) =>
    !$isScreen1300 &&
    css`
      margin-left: 80px;
    `}
`;
const HeaderContent = styled.div`
  height: 55px;
  display: flex;
  justify-content: end;
  gap: 32px;
  align-items: end;
`;
const HeaderButtons = styled.div`
  height: 32px;
  display: flex;
  gap: 26px;
  align-items: center;
`;
const DropdownsContainerStyled = styled.div`
  display: flex;
  gap: 16px;
`;
const LabelContainerStyled = styled.div`
  display: flex;
  gap: 8px;
`;
const DescriptionStyled = styled.p`
  color: #000000a6;
  font-size: 12px;
  margin: 0;
`;
const DividerStyled = styled.div`
  height: 24px;
  width: 1px;
  background: #ffffff26;
  display: flex;
`;
const FlexStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
