import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import { getPendingDealsCount } from '../api/getPendingDealsCount';

type TSidebarState = {
  guaranteePendingDealsCount: number;
  nonGuaranteePendingDealsCount: number;
  getInitialPendingDealsCount: () => void;
  setPendingDealsCountFromWS: (
    guaranteeCount: number,
    nonGuaranteeCount: number
  ) => void;
  isCollapsedSidebar: boolean;
  setIsCollapsedSidebar: (value: boolean) => void;
};

export const useSidebarState = create<TSidebarState>()(
  devtools(
    (set, get) => ({
      guaranteePendingDealsCount: 0,
      nonGuaranteePendingDealsCount: 0,
      isCollapsedSidebar: false,
      getInitialPendingDealsCount: async () => {
        const res = await getPendingDealsCount();
        if (res) {
          set(
            produce((draft) => {
              draft.guaranteePendingDealsCount = res.guarantee_count;
              draft.nonGuaranteePendingDealsCount = res.non_guarantee_count;
            })
          );
        }
      },
      setPendingDealsCountFromWS: (
        guaranteeCount: number,
        nonGuaranteeCount: number
      ) => {
        set(
          produce((draft) => {
            draft.guaranteePendingDealsCount = guaranteeCount;
            draft.nonGuaranteePendingDealsCount = nonGuaranteeCount;
          })
        );
      },
      setIsCollapsedSidebar: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isCollapsedSidebar = value;
          })
        );
      },
    }),
    {
      anonymousActionType: 'useSidebarState action',
      name: 'userState',
    }
  )
);
