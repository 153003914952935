import React, { ReactNode, useState } from 'react';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import { axios } from '../../../../../exios';
import { ModalKeys } from '../../model/enums';
import { Input } from 'antd';
import { useModalState } from '../../model/useModalState';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@pankod/refine-react-router-v6';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  ButtonStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  DisabledReasonStyled,
  HelperTextStyled,
  ModalBodyStyled,
  ModalStyled,
  ModalTitleStyled,
  ReasonContentStyled,
  ReasonTitleStyled,
} from './ModalCancelTransaction.styles';
import { DEAL_STATUS } from 'shared/constants';
import { TDealsStatus } from 'shared/types';
import { showErrorNotification, showInfoNotification } from 'shared/helpers/notification'

const CRYPTO_CURRENCY = 'USDT';

const CANCEL_REASON = {
  decline: 'decline',
  expired: 'expired',
  noGuaranteeDeposit: 'noGuaranteeDeposit',
} as const;

export interface ICancelModalOptions {
  id: string;
  name: string;
  status: TDealsStatus;
  refetchData: () => void;
  isBuyingCrypto?: boolean;
  isGuarantee?: boolean;
  isCash?: boolean;
}

export const ModalCancelTransaction = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const { TextArea } = Input;

  const [value, setValue] = useState('');

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalCancelTransaction)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) =>
      state.getModal(ModalKeys.ModalCancelTransaction)
        .options as ICancelModalOptions
  );

  const dealStatus: TDealsStatus = modalOptions.status
  const isGuarantee = !!modalOptions.isGuarantee

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalCancelTransaction);
    navigate(
      `/${isGuarantee ? 'transactions' : 'no-guarantee-transactions'}/request/${
        modalOptions.id
      }`
    );
  };

  const addDealLinkToString = (str: string) => {
    const notfifContent: ReactNode[] = str.split('%dealLink%');

    if (notfifContent.length > 1) {
      const notifLink = (
        <ButtonAsLinkStyled
          key="notification-deal-link"
          onClick={navigateToCurrentRequestHandler}
        >
          {modalOptions.name}
        </ButtonAsLinkStyled>
      );

      notfifContent.splice(1, 0, notifLink);
    }

    return notfifContent;
  };

  const showSuccessNotif = () => {
    const isBuying = !!modalOptions.isBuyingCrypto;
    const isPending = dealStatus === DEAL_STATUS.pending;
    let notifDesc = 'Сделка dealLink на продажу cryptoKey отменена'; // isBuying = false, isPending = false

    if (isBuying && !isPending) {
      notifDesc = 'Сделка dealLink на покупку cryptoKey отменена';
    } else if (!isBuying && isPending) {
      notifDesc = 'Сделка dealLink на продажу cryptoKey отклонена';
    } else if (isBuying && isPending) {
      notifDesc = 'Сделка dealLink на покупку cryptoKey отклонена';
    }

    const notifContent = addDealLinkToString(
      t(notifDesc, {
        cryptoKey: CRYPTO_CURRENCY,
      })
    );

    showInfoNotification({
      message: t(isPending ? 'Сделка отклонена' : 'Сделка отменена'),
      description: <>{notifContent}</>,
      style: {
        width: '400px',
      },
    });
  };

  const showErrorNotif = () => {
    showErrorNotification({
      message: t('shared:тексты.Ошибка запроса'),
      description: t(
        'shared:ошибки.Произошла ошибка при выполнении запроса точка Пожалуйста запятая попробуйте повторить запрос позже точка'
      ),
    });
  }

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios.put(
        `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/cancel`,
        {
          reason: value || '',
        }
      );
      modalOptions?.refetchData();

      showSuccessNotif();
    } catch {
      console.error('An error occurred while updating the transaction status');
      
      showErrorNotif()
    } finally {
      closeModal(ModalKeys.ModalCancelTransaction);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalCancelTransaction);
  };

  /**
   * EugeneB: тут логика в том что на данный момент для отмены сделки у нас есть только три типа модалок
   * Два из них можно сказать исключительные и привязаны к конкретному статусу сделки: pending и warranty_collateral
   * Третий, вне зависимости от данных сделки - это отмена в статус expired с текстовкой про невовремя предоставленную оплату
   */
  let cancelReason: typeof CANCEL_REASON[keyof typeof CANCEL_REASON] =
    CANCEL_REASON.expired;

  if (dealStatus === DEAL_STATUS.warrantyCollateral) {
    cancelReason = CANCEL_REASON.noGuaranteeDeposit;
  } else if (dealStatus === DEAL_STATUS.pending) {
    cancelReason = CANCEL_REASON.decline;
  }

  const getTitleKey = (isBuying?: boolean) => {
    if (cancelReason === CANCEL_REASON.noGuaranteeDeposit) {
      return isBuying
        ? `Отклонить сделку на покупку dealId вопрос`
        : `Отклонить сделку на продажу dealId вопрос`;
    } else if (CANCEL_REASON.decline) {
      return 'shared:тексты.Отклонение сделки';
    }

    return 'shared:тексты.Отмена сделки';
  };

  let contentBlock: ReactNode = null;

  if (cancelReason === CANCEL_REASON.decline) {
    contentBlock = (
      <>
        <CommentBlockStyled>
          <CommentTitleStyled>{t('Причина отмены')}</CommentTitleStyled>
          <TextArea
            showCount
            maxLength={5000}
            onChange={onChangeHandler}
            placeholder={String(t('shared:тексты.Введите комментарий'))}
            value={value}
          />
        </CommentBlockStyled>
        <HelperTextStyled>
          {t(
            'В случае отклонения запятая действия по сделке больше не будут доступны точка'
          )}
        </HelperTextStyled>
      </>
    );
  } else if (cancelReason === CANCEL_REASON.noGuaranteeDeposit) {
    contentBlock = (
      <>
        <ReasonContentStyled>
          {t(
            'Сделка будет отменена запятая вы не сможете вернуть её в работу точка'
          )}{' '}
          {t('Данное действие негативно скажется на рейтинге клиента точка')}
        </ReasonContentStyled>
        <DisabledReasonStyled>
          <ReasonTitleStyled>{t('Причина отмены')}</ReasonTitleStyled>
          <ReasonContentStyled>
            {t(
              'Гарант не депонирован в установленное время не менее чем за 2 часа до обмена'
            )}
          </ReasonContentStyled>
        </DisabledReasonStyled>
      </>
    );
  } else if (cancelReason === CANCEL_REASON.expired) {
    const paragraph = addDealLinkToString(
      t(
        'Сделка dealLink будет отменена запятая вы не сможете вернуть её в работу точка'
      )
    );

    contentBlock = (
      <>
        <ReasonContentStyled>{paragraph}</ReasonContentStyled>
        <DisabledReasonStyled>
          <ReasonTitleStyled>{t('Причина отмены')}</ReasonTitleStyled>
          <ReasonContentStyled>
            {t('Оплата не предоставлена вовремя не менее чем пол часа с момента назначенного обмена')}
          </ReasonContentStyled>
        </DisabledReasonStyled>
      </>
    );
  }

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={636}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled>
          {t(getTitleKey(modalOptions.isBuyingCrypto), {
            dealId: modalOptions.name,
          })}
        </ModalTitleStyled>

        {contentBlock}

        <ButtonGroupStyled>
          <ButtonStyled onClick={handleCloseModal}>
            {t('shared:кнопки.Назад')}
          </ButtonStyled>
          <ButtonStyled onClick={handleChangeOfferRequestStatus} type="primary">
            {t(
              dealStatus === DEAL_STATUS.pending
                ? 'Отклонить сделку'
                : 'Отменить сделку'
            )}
          </ButtonStyled>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </ModalStyled>
  );
};
