import { Flex } from '../../../../shared/components/styled';
import { EnvironmentOutlined, UserAddOutlined } from '@ant-design/icons';
import { ReactNode, useContext, useMemo } from 'react';
import { TCashAccountOwnerType } from '../../../../shared/types/offers';
import { useTranslation } from 'react-i18next';
import {
  FlexBlockStyled,
  SegmentedStyled,
} from '../OfferRequestConfirm.styles';
import { useRequestConfirmState } from '../../model/useRequestConfirmState';
import { ConfigProvider, Popover, Select } from '@pankod/refine-antd';
import { ExchangePointsContext } from '../../../../shared/contexts/exchange-points-context';
import { IAccount } from '../../../ExchangePoints/interface';

type TOptionType = {
  label: ReactNode;
  value: TCashAccountOwnerType;
  disabled?: boolean;
};

const ReceiveCashBlock = () => {
  const { t } = useTranslation(['offers', 'shared']);
  const { activePoint } = useContext(ExchangePointsContext);

  const requestData = useRequestConfirmState((state) => state.requestData);

  const exchangePointAccounts = useRequestConfirmState(
    (state) => state.exchangePointAccounts
  );
  const exchangePointStaffs = useRequestConfirmState(
    (state) => state.exchangePointStaffs
  );

  const changeRequestDataField = useRequestConfirmState(
    (state) => state.changeRequestDataField
  );

  const isShowPopover = activePoint && !exchangePointAccounts?.length;

  const staffsOptions = useMemo(
    () =>
      exchangePointStaffs.map((staff) => ({
        label: `${staff.firstname} ${staff.lastname}`,
        value: staff.uid,
      })),
    [exchangePointStaffs]
  );

  const accountsOptions = useMemo(
    () =>
      exchangePointAccounts.map((account) => ({
        label: account.shortname,
        value: account.id,
      })),
    [exchangePointAccounts.length]
  );

  const options: TOptionType[] = [
    {
      label: (
        <Popover
          content={t(
            'Вы не зарегистрировали в системе кассу для хранения средств обменного пункта',
            { exchangePointName: activePoint?.name }
          )}
          placement={'topLeft'}
          color="rgba(255, 241, 240, 1)"
          overlayInnerStyle={{ width: 320 }}
          zIndex={isShowPopover ? 100 : -1}
        >
          <Flex gap={4}>
            <EnvironmentOutlined style={{ fontSize: '16px' }} />
            <span>{t('shared:тексты.Счет пункта')}</span>
          </Flex>
        </Popover>
      ),
      value: 'exchange_point',
      disabled: !exchangePointAccounts.length,
    },
    {
      label: (
        <Flex gap={4}>
          <UserAddOutlined style={{ fontSize: '16px' }} />
          <span>{t('shared:тексты.Сотрудник')}</span>
        </Flex>
      ),
      value: 'personal_account',
      disabled: !exchangePointStaffs.length,
    },
  ];

  const handleSegmentedChange = (value: string | number) => {
    changeRequestDataField(
      'cash_account_owner_type',
      value as TCashAccountOwnerType
    );
  };

  const handleChangeAccount = (value: number) => {
    changeRequestDataField('cash_account_id', value);
  };

  const handleChangeStaff = (value: string) => {
    changeRequestDataField('cash_personal_user_account_uid', value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            controlHeight: 32,
          },
        },
      }}
    >
      <FlexBlockStyled
        align={'stretch'}
        justify={'flex-start'}
        gap={28}
        vertical
      >
        <SegmentedStyled
          value={requestData?.cash_account_owner_type ?? undefined}
          options={options}
          onChange={handleSegmentedChange}
        />
        {requestData?.cash_account_owner_type === 'exchange_point' ? (
          <Select
            value={requestData?.cash_account_id}
            onChange={handleChangeAccount}
            options={accountsOptions}
            size={'middle'}
            disabled={!accountsOptions.length}
          />
        ) : (
          <Select
            value={requestData?.cash_personal_user_account_uid}
            onChange={handleChangeStaff}
            options={staffsOptions}
            size={'middle'}
            disabled={!staffsOptions.length}
          />
        )}
      </FlexBlockStyled>
    </ConfigProvider>
  );
};

export default ReceiveCashBlock;
