import styled from 'styled-components';

export const CancelButtonInDropdownStyled = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: rgba(255, 77, 79, 1);
  &svg {
    color: rgba(255, 77, 79, 1);
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const CancelButtonInDropdownDisabledStyled = styled.button`
  cursor: not-allowed;
  background-color: transparent;
  border: none;
  color: rgb(143, 143, 143);

  &svg {
    color: rgb(143, 143, 143);
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const AcceptButtonInDropdownStyled = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #1677ff;
  &svg {
    color: #1677ff;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const ButtonInDropdownStyled = styled.button<{ $blueColor?: boolean }>`
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: ${({ $blueColor }) =>
    $blueColor ? '#1677ff' : 'rgba(0, 0, 0, 0.88)'};
  & svg {
    color: ${({ $blueColor }) =>
      $blueColor ? '#1677ff' : 'rgba(0, 0, 0, 0.88)'};
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`;

export const CenteredStatusCaseStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
