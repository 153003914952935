import React from 'react';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { axios } from '../../../../../exios';
import { API_URL } from '../../../../../../packages/keycloak-client/constants';
import {
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalRowStyled,
  ModalTextStyled,
  ModalTitleStyled,
} from './ModalConfirmReceiptOfCash.styles';
import { useNavigate } from '@pankod/refine-react-router-v6';

export const ModalConfirmReceiptOfCash = () => {
  const { t } = useTranslation(['offers', 'shared']);

  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalConfirmReceiptOfCash)
  );
  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalConfirmReceiptOfCash).options
  );
  const closeModalHandler = () => {
    closeModal(ModalKeys.ModalConfirmReceiptOfCash);
  };

  const navigateToCurrentRequestHandler = () => {
    closeModal(ModalKeys.ModalConfirmReceiptOfCash);
    navigate(
      `/${
        modalOptions?.isGuarantee ? 'transactions' : 'no-guarantee-transactions'
      }/request/${modalOptions.id}`
    );
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      await axios.put(
        `${API_URL}/exchange-point-offer-requests/${modalOptions.id}/confirm`
      );
      modalOptions?.refetchData();
      notification.success({
        message: t('Сделка успешно выполнена'),
        icon: <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />,
        description: (
          <div>
            {t('Сделка')}
            <ButtonAsLinkStyled onClick={navigateToCurrentRequestHandler}>
              {modalOptions.name}
            </ButtonAsLinkStyled>
            {t(
              `на ${
                modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
              } USDT успешно выполнена`
            )}
          </div>
        ),
        style: {
          borderLeft: '6px solid rgba(19, 194, 194, 1)',
          width: '400px',
        },
      });
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalConfirmReceiptOfCash);
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalConfirmReceiptOfCash);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={closeModalHandler}
      footer={null}
      width={460}
      closable={false}
      centered
    >
      <ModalBodyStyled>
        <ModalRowStyled>
          <ExclamationCircleOutlined
            size={21}
            style={{ color: '#1677FF', fontSize: 21 }}
          />
          <ModalTextStyled>
            <ModalTitleStyled>
              {t('Подтвердить получение оплаты вопрос')}
            </ModalTitleStyled>
            <ModalDescriptionStyled>
              {t(
                'Гарант будет переведен на счет клиента запятая и сделка будет считаться выполненной точка Отмена действия будет недоступна точка'
              )}
            </ModalDescriptionStyled>
          </ModalTextStyled>
        </ModalRowStyled>
        <ButtonGroupStyled>
          <Button onClick={handleCloseModal}>
            {t('shared:кнопки.Отмена')}
          </Button>
          <Button onClick={handleChangeOfferRequestStatus} type="primary">
            {t('shared:кнопки.Подтвердить')}
          </Button>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </Modal>
  );
};
