import styled from 'styled-components';
import { Button, Popconfirm, Spin } from '@pankod/refine-antd';
import { Card, Input, Segmented, Select } from '@pankod/refine-antd';
import { Flex } from '../../../shared/components/styled';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;
export const ContainerElemsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 32px 32px 0 32px;
`;
export const CardsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
export const FooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 16px;
  height: 96px;
  width: 100%;
  padding: 24px 32px 32px 32px;
  border-left: 1px solid #29292b;
  background: #14141fff;
`;
export const ButtonBackStyled = styled(Button)`
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff40;

  &&&&:hover {
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.39);
  }
`;

export const InputStyled = styled(Input)`
  width: 180px;
  &.ant-input-affix-wrapper {
    box-shadow: none;
  }
`;

export const CardStyled = styled(Card)`
  padding: 24px 8px;

  .ant-card-body {
    white-space: wrap;
    align-items: flex-start;
    display: flex;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;

    > :first-child {
      min-width: 380px;
    }
    > :nth-child(3) {
      min-width: 345px;
    }
    > :not(.ant-divider-vertical) {
      flex: 1;
    }

    .ant-divider {
      height: auto;
      margin: 0 8px;
      align-self: stretch;
    }
  }
`;

export const InfoBlockWrapper = styled(Flex)`
  flex: 1;
  padding: 0 24px;
`;

export const ButtonLinkStyled = styled(Button)`
  padding: 0;
`;

export const SegmentedStyled = styled(Segmented)`
  & .ant-segmented-item-label {
    padding: 2px 25px;
  }
`;

export const LoaderStyled = styled(Spin)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const FlexBlockStyled = styled(Flex)`
  height: 100%;
  max-width: 350px;
  word-break: break-all;
`;

export const DateStyled = styled.span`
  margin-right: 10px;
`;

export const StyledText = styled.span`
  color: #000000a6;
`;

export const SpaceStyled = styled.div`
  width: 100%;
  min-height: 45px;
`;
